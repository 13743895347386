import { clio } from 'themes/clio';
import { litify } from 'themes/litify';
import { myCase } from 'themes/mycase';
import { filevine } from 'themes/filevine';

export const getLPMSTheme = () => {
  let theme = clio;
  let params = new URLSearchParams(window.location.search);
  let lpms = params.get('lpms');
  if (lpms) {
    if (lpms.toLowerCase() === litify.id.toLowerCase()) {
      theme = litify;
    } else if (lpms.toLowerCase() === myCase.id.toLowerCase()) {
      theme = myCase;
    } else if (lpms.toLowerCase() === filevine.id.toLowerCase()) {
      theme = filevine;
    }
  }
  return theme;
};
