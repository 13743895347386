import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser } from 'utils/api';
import { datadogRum } from '@datadog/browser-rum';
import { getLPMSTheme } from 'utils/theme';

const CurrentUserContext = createContext(null);

const theme = getLPMSTheme();
const CurrentUserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser()
      .then((usr) => setUser(usr))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        lpmsType: theme?.id,
      });
    }
  }, [user]);

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export { CurrentUserContext, CurrentUserContextProvider };
