import React, { useContext, useEffect, useState } from 'react';
import Button from 'components/Button';
import { Link } from 'components/Link';
import { getJobs } from 'utils/api';
import { Table } from 'components/Table';
import Logo from 'components/Logo';
import styled, { withTheme } from 'styled-components';
import Moment from 'react-moment';
import moment from 'moment';
import Pill from 'components/Pill';
import { CurrentUserContext } from 'context/CurrentUserContext';
import { getNiceStatus } from 'domain/job';
import { getFlagDetails, getNiceFlagType } from 'domain/flags';
import Loader from 'components/Loader';
import { MatterContext } from 'context/MatterContext';
import { filevine } from 'themes/filevine';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  > div:first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`;

const SignOutContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const PillContainer = styled.div`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const EmptyContainer = styled.div`
  text-align: center;
  font-size: 1.6rem;
  margin: 2.4rem 0;
  line-height: 2.4rem;
`;

const EmptyImage = styled.img`
  width: 50rem;
  display: block;
  margin: 0 auto 2rem;
`;

// const getAlerts = (job) => {
//   let warnings = [];
//   job.flags
//     ?.filter((flag) => !flag.resolved)
//     .forEach((flag) =>
//       warnings.push({
//         title: `Flagged: ${getNiceFlagType(flag.type)}`,
//         extra: getFlagDetails(flag),
//       }),
//     );

//   const currentUser = useContext(CurrentUserContext);

//   let unreadChatCount =
//     job.messages?.filter((msg) =>
//       msg.recipients.find((r) => r.id === currentUser.id && !r.pivot.read),
//     ).length ?? 0;

//   if (unreadChatCount > 0) {
//     warnings.push({
//       title: `${unreadChatCount} Unread Message${unreadChatCount !== 1 ? 's' : ''}`,
//     });
//   }
//   return warnings;
// };

const JobListScreen = ({ theme }) => {
  const [jobs, setJobs] = useState(null);
  const { matter } = useContext(MatterContext);
  const currentUser = useContext(CurrentUserContext);
  const getAlerts = (job) => {
    let warnings = [];
    job.flags
      ?.filter((flag) => !flag.resolved)
      .forEach((flag) =>
        warnings.push({
          title: `Flagged: ${getNiceFlagType(flag.type)}`,
          extra: getFlagDetails(flag),
        }),
      );

    let unreadChatCount =
      job.messages?.filter((msg) =>
        msg.recipients.find((r) => r.id === currentUser?.id && !r.pivot.read),
      ).length ?? 0;

    if (unreadChatCount > 0) {
      warnings.push({
        title: `${unreadChatCount} Unread Message${unreadChatCount !== 1 ? 's' : ''}`,
      });
    }
    return warnings;
  };
  console.log('JobListScreen render:', {
    matter,
    jobs,
    currentUser,
    theme: theme.id,
  });

  useEffect(() => {
    if (!matter) {
      return;
    }

    const params = {
      with_messages: true,
      sort_dir: 'desc',
      sort_by: 'originated_at',
      lpms: theme.id,
      lpms_id: matter?.id,
      limit: 150,
    };

    // Don't add project_id, just let lpms=FILEVINE identify it as a Filevine request
    console.log('Fetching jobs with params:', params);

    getJobs(params)
      .then((response) => {
        console.log('Jobs response:', response);
        if (response && Array.isArray(response.data)) {
          setJobs(response.data);
        } else if (Array.isArray(response)) {
          setJobs(response);
        } else {
          console.error('Unexpected jobs response format:', response);
          setJobs([]);
        }
      })
      .catch((err) => {
        console.error('Error fetching jobs:', err);
        setJobs([]);
      });
  }, [matter, theme.id]);

  if (!jobs || !matter) {
    console.log('Showing loader because:', {
      noJobs: !jobs,
      noMatter: !matter,
    });

    return <Loader />;
  }

  return (
    <React.Fragment>
      <Header>
        <Logo />
        <Button to={'/jobs/new'}>New serve request</Button>
      </Header>
      {jobs.length === 0 && (
        <EmptyContainer>
          <EmptyImage src={'/images/empty.png'} />
          This is where your serve requests for this {theme.matter} would show up once
          submitted.
          <br />
          Click the "New serve request" button in the upper right corner of your
          screen to get started.
        </EmptyContainer>
      )}
      {jobs.length > 0 && (
        <Table
          fields={[
            {
              title: 'Servee',
              field: 'servee.name',
              display: (row) => (
                <Link to={`/jobs/${row.id}`}>{row.servee.name}</Link>
              ),
            },
            {
              title: 'Status',
              field: 'status',
              display: (row) => (
                <Pill
                  variant={
                    {
                      UNASSIGNED: 'secondary',
                      PENDING_FILING: 'secondary',
                      ASSIGNED: 'primary',
                      IN_PROGRESS: 'primary',
                      PENDING_NOTARY: 'warning',
                      FILING_AFFIDAVIT: 'warning',
                      IN_REVIEW: 'success',
                      PENDING_RATING: 'success',
                      COMPLETED: 'success',
                      REASSIGNED: 'destructive',
                      CANCELED: 'destructive',
                    }[row.status]
                  }
                >
                  {getNiceStatus(row)}
                </Pill>
              ),
            },
            {
              title: 'Attempts',
              field: 'attempts_made',
              display: (row) =>
                `${row.attempts_made} / ${row.serve_pricing?.total_attempts ?? 4}`,
            },
            {
              title: 'Originated',
              field: 'originated_at',
              display: (row) => (
                <Moment
                  format="L"
                  date={row.originated_at}
                  title={moment().format('LLL')}
                />
              ),
            },
            {
              title: 'Server',
              field: 'server.name',
              display: (row) => <div>{row?.server?.name ?? '-'}</div>,
            },
            {
              title: 'Speed',
              field: 'serve_pricing.name',
              display: (row) => (
                <Pill
                  variant={
                    {
                      Standard: 'primary',
                      Expedited: 'warning',
                      'Same Day': 'destructive',
                      Post: 'success',
                      Garnishment: 'info',
                      Envelope: 'info',
                    }[row.serve_pricing?.name ?? 'primary']
                  }
                >
                  {row.serve_pricing?.name ?? ''}
                </Pill>
              ),
            },
            {
              title: 'Owner',
              field: 'user.name',
            },
            {
              title: 'Alerts',
              display: (row) =>
                getAlerts(row).map((warning, i) => (
                  <PillContainer key={i}>
                    <Pill variant={'warning'} title={warning.extra}>
                      {warning.title}
                    </Pill>
                  </PillContainer>
                )),
            },
            // {
            //     title: 'Time Remaining',
            //     field: 'due_date',
            //     display: row => <div>{row.serve_pricing?.name ?? ''}</div>
            // }
          ]}
          data={jobs}
        />
      )}
      <Footer>
        <div>
          {currentUser?.administrator && (
            theme === filevine ? (
              <Button 
                variant={'secondary'} 
                to={`${APP_URL}integrations`}
                target={'_blank'}
              >
                Integration settings
              </Button>
            ) : (
              <Button variant={'secondary'} to={'/settings'}>
                Integration settings
              </Button>
            )
          )}
          {theme === filevine ? (
            <Button 
              variant={'secondary'} 
              to={'https://help.proofserve.com/client/en/'} 
              target={'_blank'}
            >
              Proof customer support
            </Button>
          ) : (
            <Button variant={'secondary'} to={'/support'}>
              Proof customer support
            </Button>
          )}
          <Button variant={'secondary'} to={APP_URL} target={'_blank'}>
            Open Proof
          </Button>
        </div>
        {theme === filevine && (
          <SignOutContainer>
            <Button 
              variant={'secondary'} 
              onClick={() => {
                document.cookie = `${AUTH_TOKEN_COOKIE}=; path=/; domain=${COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                if (window.parent) {
                  window.parent.postMessage({ type: 'PROOF_LPMS_LOGOUT' }, '*');
                }
                window.location.reload();
              }}
            >
              Sign Out
            </Button>
          </SignOutContainer>
        )}
      </Footer>
    </React.Fragment>
  );
};

export default withTheme(JobListScreen);
