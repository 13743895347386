import Button from 'components/Button'
import { Card } from 'components/Card'
import { Checkbox } from 'components/Checkbox'
import { Input } from 'components/Input'
import Loader from 'components/Loader'
import Select from 'components/Select'
import { useToast } from 'components/Toast'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import { clio } from 'themes/clio'
import { litify } from 'themes/litify'
import { myCase } from 'themes/mycase'
import {
	getClioDocumentCategories,
	getClioExpenseCategories,
	getCurrentLawFirm,
	getStates,
	updateCurrentLawFirm,
} from 'utils/api'
import { isFirmAddressFullyPopulated } from 'utils/funcs'
import EditFirmAddress from './modals/EditFirmAddress'

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 1rem;
`

const Instructions = styled.div`
	margin-bottom: 2rem;
	font-weight: 500;
`

const ColumnTitle = styled.div`
	font-weight: 700;
	font-size: 1.6rem;
	margin-bottom: 1.2rem;
`

const ColumnGroup = styled.div`
	display: flex;
	flex-direction: row;
`

const Column = styled.div`
	flex: 1;

	&:not(:last-child) {
		margin-right: 7.2rem;
	}

	${Column} &:not(:last-child) {
		margin-right: 2.4rem;
	}
`

const RowGroup = styled.div`
	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}
`

const Row = styled.div`
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}

	> :last-child {
		max-width: 50rem;
	}
`

const Label = styled.label`
	font-weight: 700;
	font-size: 1.3rem;
	margin-bottom: 0.5rem;
`

const SettingsScreen = ({ theme }) => {
	const navigate = useNavigate()
	const [states, setStates] = useState([])
	const [settings, setSettings] = useState(null)
	const [firm, setFirm] = useState(null)
	const [loading, setLoading] = useState(false)
	const { addToast } = useToast()

	// clio
	const [expenseCategories, setExpenseCategories] = useState([])
	const [documentCategories, setDocumentCategories] = useState([])

	const onSubmit = (e) => {
		e.preventDefault()
		setLoading(true)
		updateCurrentLawFirm({
			...firm,
			lpms_settings: settings,
		})
			.then(() => {
				setLoading(false)
				addToast('success', 'Success', 'Integration settings saved successfully')
			})
			.catch(() => {
				setLoading(false)
				addToast(
					'error',
					'Error',
					'Failed to save integration settings. If the problem persists, please contact Proof customer support'
				)
			})
	}

	useEffect(() => {
		loadCurrentLawFirm()
		getStates()
			.then((s) => setStates(s))
			.catch(console.error)
		if (theme === clio) {
			getClioExpenseCategories()
				.then((cats) => setExpenseCategories(cats))
				.catch(console.error)
			getClioDocumentCategories()
				.then((cats) => setDocumentCategories(cats))
				.catch(console.error)
		} else if (theme === litify) {
			// TODO: litify
		} else if (theme === myCase) {
			// TODO: mycase
		}
	}, [])

	const loadCurrentLawFirm = () => {
		getCurrentLawFirm()
			.then((firm) => {
				setSettings(
					firm.lpms_settings ?? {
						clio: {
							create_expense: false,
							expense_category_id: null,
							download_invoice: false,
							invoices_folder: '',
							invoice_document_category_id: null,
							download_affidavit: false,
							affidavits_folder: '',
							affidavit_document_category_id: null,
						},
						litify: {
							create_expense: false,
							download_invoice: false,
							invoices_folder: '',
							download_affidavit: false,
							affidavits_folder: '',
						},
						mycase: {
							create_expense: false,
							expense_activity_name: '',
							expense_billable: false,
							download_invoice: false,
							invoices_folder: '',
							download_affidavit: false,
							affidavits_folder: '',
						},
					}
				)
				setFirm(firm)
			})
			.catch(console.error)
	}

	if (!settings || loading) {
		return <Loader />
	}

	const clioForm = () => (
		<>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.clio.create_expense}
						onChange={(val) =>
							setSettings({
								...settings,
								clio: { ...settings.clio, create_expense: val },
							})
						}
						title={`Automatically create ${theme.name} expense activities for Proof invoices`}
					/>
				</Row>
				{settings.clio.create_expense && (
					<>
						<Row>
							<Label>Expense category</Label>
							<Select
								options={expenseCategories.map((cat) => ({
									label: cat.name,
									value: cat.id,
								}))}
								theme={theme}
								value={settings.clio.expense_category_id}
								onChange={(val) =>
									setSettings({
										...settings,
										clio: { ...settings.clio, expense_category_id: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.clio.download_affidavit}
						onChange={(val) =>
							setSettings({
								...settings,
								clio: { ...settings.clio, download_affidavit: val },
							})
						}
						title={`Automatically upload Proof Affidavits of Service/Non-Service to ${theme.name} Documents`}
					/>
				</Row>
				{settings.clio.download_affidavit && (
					<>
						<Row>
							<Label>Folder:</Label>
							<Input
								value={settings.clio.affidavits_folder}
								onChange={(val) =>
									setSettings({
										...settings,
										clio: { ...settings.clio, affidavits_folder: val },
									})
								}
							/>
						</Row>
						<Row>
							<Label>Document Category:</Label>
							<Select
								options={documentCategories.map((cat) => ({
									label: cat.name,
									value: cat.id,
								}))}
								theme={theme}
								value={settings.clio.affidavit_document_category_id}
								onChange={(val) =>
									setSettings({
										...settings,
										clio: { ...settings.clio, affidavit_document_category_id: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.clio.download_invoice}
						onChange={(val) =>
							setSettings({
								...settings,
								clio: { ...settings.clio, download_invoice: val },
							})
						}
						title={`Automatically upload a PDF copy of Proof invoices to ${theme.name} Documents`}
					/>
				</Row>
				{settings.clio.download_invoice && (
					<>
						<Row>
							<Label>Folder:</Label>
							<Input
								value={settings.clio.invoices_folder}
								onChange={(val) =>
									setSettings({
										...settings,
										clio: { ...settings.clio, invoices_folder: val },
									})
								}
							/>
						</Row>
						<Row>
							<Label>Document Category:</Label>
							<Select
								options={documentCategories.map((cat) => ({
									label: cat.name,
									value: cat.id,
								}))}
								theme={theme}
								value={settings.clio.invoice_document_category_id}
								onChange={(val) =>
									setSettings({
										...settings,
										clio: { ...settings.clio, invoice_document_category_id: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
		</>
	)

	const litifyForm = () => (
		<>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.litify.create_expense}
						onChange={(val) =>
							setSettings({
								...settings,
								litify: { ...settings.litify, create_expense: val },
							})
						}
						title={`Automatically create ${theme.name} expense activities for Proof invoices`}
					/>
				</Row>
				{/* Show this row only if create_expense is true */}
			    {settings.litify.create_expense && (
				<Row>
					<Checkbox
						value={settings.litify.do_not_set_expense_status}
						onChange={(val) =>
							setSettings({
								...settings,
								litify: { ...settings.litify, do_not_set_expense_status: val },
							})
						}
						title={`Do not set ${theme.name} expense status`}
					/>
				</Row>
			    )}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.litify.download_affidavit}
						onChange={(val) =>
							setSettings({
								...settings,
								litify: { ...settings.litify, download_affidavit: val },
							})
						}
						title={`Automatically upload Proof Affidavits of Service/Non-Service to ${theme.name} Documents`}
					/>
				</Row>
				{settings.litify.download_affidavit && (
					<>
						<Row>
							<Label>Folder:</Label>
							<Input
								value={settings.litify.affidavits_folder}
								onChange={(val) =>
									setSettings({
										...settings,
										litify: { ...settings.litify, affidavits_folder: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.litify.download_invoice}
						onChange={(val) =>
							setSettings({
								...settings,
								litify: { ...settings.litify, download_invoice: val },
							})
						}
						title={`Automatically upload a PDF copy of Proof invoices to ${theme.name} Documents`}
					/>
				</Row>
				{settings.litify.download_invoice && (
					<>
						<Row>
							<Label>Folder:</Label>
							<Input
								value={settings.litify.invoices_folder}
								onChange={(val) =>
									setSettings({
										...settings,
										litify: { ...settings.litify, invoices_folder: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.litify.should_update}
						onChange={(val) =>
							setSettings({
								...settings,
								litify: { ...settings.litify, should_update: val },
							})
						}
						title={`Sync Job Data to ${theme.name} Proof Serve Custom Object`}
					/>
				</Row>
			</RowGroup>
		</>
	)

	const myCaseForm = () => (
		<>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.mycase.create_expense}
						onChange={(val) => {
							setSettings({
								...settings,
								mycase: { ...settings.mycase, create_expense: val },
							})
							if (!settings.mycase.expense_activity_name) {
								setSettings({
									...settings,
									mycase: {
										...settings.mycase,
										expense_activity_name: 'Service of Process',
									},
								})
							}
						}}
						title={`Automatically create ${theme.name} expense activities for Proof invoices`}
					/>
				</Row>
				{settings.mycase.create_expense && (
					<Row>
						<Label>Activity Name:</Label>
						<Input
							value={settings.mycase.expense_activity_name}
							required={true}
							onChange={(val) =>
								setSettings({
									...settings,
									mycase: { ...settings.mycase, expense_activity_name: val },
								})
							}
						/>
					</Row>
				)}
				{settings.mycase.create_expense && (
					<Row>
						<Checkbox
							value={settings.mycase.expense_billable}
							onChange={(val) =>
								setSettings({
									...settings,
									mycase: { ...settings.mycase, expense_billable: val },
								})
							}
							title={`Mark expense billable`}
						/>
					</Row>
				)}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.mycase.download_affidavit}
						onChange={(val) =>
							setSettings({
								...settings,
								mycase: { ...settings.mycase, download_affidavit: val },
							})
						}
						title={`Automatically upload Proof Affidavits of Service/Non-Service to ${theme.name} Documents`}
					/>
				</Row>
				{settings.mycase.download_affidavit && (
					<>
						<Row>
							<Label>Folder:</Label>
							<Input
								value={settings.mycase.affidavits_folder}
								onChange={(val) =>
									setSettings({
										...settings,
										mycase: { ...settings.mycase, affidavits_folder: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
			<RowGroup>
				<Row>
					<Checkbox
						value={settings.mycase.download_invoice}
						onChange={(val) =>
							setSettings({
								...settings,
								mycase: { ...settings.mycase, download_invoice: val },
							})
						}
						title={`Automatically upload a PDF copy of Proof invoices to ${theme.name} Documents`}
					/>
				</Row>
				{settings.mycase.download_invoice && (
					<>
						<Row>
							<Label>Folder:</Label>
							<Input
								value={settings.mycase.invoices_folder}
								onChange={(val) =>
									setSettings({
										...settings,
										mycase: { ...settings.mycase, invoices_folder: val },
									})
								}
							/>
						</Row>
					</>
				)}
			</RowGroup>
		</>
	)
	return (
		<>
			<Header>
				<Button variant={'secondary'} onClick={() => navigate(-1)}>
					Go back
				</Button>
			</Header>
			<Card title={'Integration Settings'}>
				<form onSubmit={onSubmit}>
					<ColumnGroup>
						<Column>
							<Instructions>
								Please note that changes to these settings will be applied to all users of
								the integration.
							</Instructions>
							{theme === clio && clioForm()}
							{theme === litify && litifyForm()}
							{theme === myCase && myCaseForm()}
						</Column>
						<Column>
							<ColumnTitle>Firm Contact Settings</ColumnTitle>
							<RowGroup>
								<Row>
									<ColumnGroup>
										<Column>
											<Label>Firm Name:</Label>
											<Input
												value={firm.name}
												onChange={(val) =>
													setFirm({
														...firm,
														name: val,
													})
												}
											/>
										</Column>
										<Column>
											<Label>Phone Number:</Label>
											<Input
												value={firm.phone_number}
												onChange={(val) =>
													setFirm({
														...firm,
														phone_number: val,
													})
												}
											/>
										</Column>
									</ColumnGroup>
								</Row>
							</RowGroup>
							<RowGroup>
								<Row>
									<ColumnGroup>
										<Column>
											<Row>
												<Label>Address 1:</Label>
												<Input
													value={firm.address_1}
													onChange={(val) =>
														setFirm({
															...firm,
															address_1: val,
														})
													}
												/>
											</Row>
										</Column>
										<Column>
											<Row>
												<Label>Address 2:</Label>
												<Input
													value={firm.address_2}
													onChange={(val) =>
														setFirm({
															...firm,
															address_2: val,
														})
													}
												/>
											</Row>
										</Column>
									</ColumnGroup>
								</Row>
							</RowGroup>
							<RowGroup>
								<Row>
									<ColumnGroup>
										<Column>
											<Row>
												<Label>City:</Label>
												<Input
													value={firm.city}
													onChange={(val) =>
														setFirm({
															...firm,
															city: val,
														})
													}
												/>
											</Row>
										</Column>
										<Column>
											<Row>
												<Label>State:</Label>
												<Select
													options={states.map((s) => ({ value: s.id, label: s.name }))}
													placeholder={'Choose a state...'}
													value={firm.state_id}
													required={true}
													onChange={(val) => setFirm({ ...firm, state_id: val })}
													theme={theme}
												/>
											</Row>
										</Column>
										<Column>
											<Row>
												<Label>Zip:</Label>
												<Input
													value={firm.zip}
													onChange={(val) =>
														setFirm({
															...firm,
															zip: val,
														})
													}
												/>
											</Row>
										</Column>
									</ColumnGroup>
								</Row>
							</RowGroup>
							<RowGroup>
								<Row>
									<ColumnGroup>
										<Column>
											<Row>
												<Label>Primary Billing Contact Email</Label>
												<Input
													value={firm.primary_billing_contact_email}
													onChange={(val) =>
														setFirm({
															...firm,
															primary_billing_contact_email: val,
														})
													}
												/>
											</Row>
										</Column>
									</ColumnGroup>
								</Row>
							</RowGroup>
						</Column>
					</ColumnGroup>
					<div style={{ marginTop: '2.4rem' }}>
						<Button type={'submit'}>Save settings</Button>
					</div>
				</form>
			</Card>
		</>
	)
}

export default withTheme(SettingsScreen)
