import React from 'react';
import { logError } from '../utils/logger';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    logError(`Error in ${this.props.name || 'component'}`, error, {
      errorInfo,
      component: this.props.name,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h3>Something went wrong in {this.props.name || 'this component'}</h3>
          <pre>{this.state.error?.message}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
