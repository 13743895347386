import axios from 'axios';
import { getCookie, deleteCookie } from 'utils/cookie';
// Keep token in memory
let authToken = getCookie(AUTH_TOKEN_COOKIE);

export const client = axios.create({
  baseURL: API_URL, // Always use API_URL from webpack DefinePlugin
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

console.log('API client configuration:', {
  nodeEnv: process.env.NODE_ENV,
  API_URL,
  isDevelopment: process.env.NODE_ENV === 'development',
  baseURL: process.env.NODE_ENV === 'development' ? '/v1/' : API_URL,
});

// Add interceptor to handle 401s
client.interceptors.response.use(
  (response) => {
    console.log('Response before unwrap:', response);
    const unwrapped = response.data;
    console.log('Response after unwrap:', unwrapped);
    return unwrapped;
  },
  (error) => {
    console.log('API Error intercepted:', {
      status: error.response?.status,
      url: error.config?.url,
      message: error.response?.data?.message
    });

    if (error.response?.status === 401) {
      // Don't clear auth if this is a Filevine integration check
      const isFilevineCheck = error.config?.url?.includes('/filevine/projects/');
      const isGraphQLQuery = error.config?.url?.includes('/query');
      const isLawFirmCheck = error.config?.url?.includes('/law-firms/current');

      // If this is part of the Filevine integration check flow, don't clear auth
      if (isFilevineCheck || (isGraphQLQuery && window.location.href.includes('filevine'))) {
        return Promise.reject(error);
      }

      // For all other 401s, clear auth
      deleteCookie(AUTH_TOKEN_COOKIE);
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

// Set default auth header
client.defaults.headers.common['Authorization'] = authToken;

// Update auth token function
export const updateAuthToken = (token) => {
  authToken = token;
  client.defaults.headers.common['Authorization'] = authToken;
};

// Initialize auth token
if (authToken) {
  updateAuthToken(authToken);
}

// client.interceptors.request.use((config) => {
//   const token = getCookie(AUTH_TOKEN_COOKIE);
//   const baseURL = config.baseURL || API_URL;

//   if (!config.headers.Authorization && token) {
//     config.headers.Authorization = token;
//   }

//   // Log complete request details
//   console.log('API Request:', {
//     url: `${baseURL}${config.url}`,
//     token: token ? `${token.substring(0, 15)}...` : 'none',
//     headers: {
//       ...config.headers,
//       Authorization: config.headers.Authorization
//         ? `Bearer ${config.headers.Authorization.substring(0, 15)}...`
//         : 'none',
//     },
//   });

//   if (!token) {
//     console.error('No auth token found');
//     return Promise.reject('No auth token');
//   }

//   // Ensure token has Bearer prefix
//   config.headers.Authorization = token.startsWith('Bearer ')
//     ? token
//     : `Bearer ${token}`;

//   console.log('Making request:', {
//     url: config.url,
//     fullUrl: `${config.baseURL}${config.url}`,
//     method: config.method,
//     headers: {
//       auth: config.headers.Authorization?.substring(0, 15) + '...',
//       ...config.headers,
//     },
//   });

//   return config;
// });

// client.interceptors.response.use(
//   (response) => {
//     console.log('Response success:', {
//       url: response.config.url,
//       status: response.status,
//       dataType: typeof response.data,
//       isArray: Array.isArray(response.data),
//       keys: response.data ? Object.keys(response.data) : null,
//       data: response.data,
//     });
//     return response.data; // Return the actual data
//   },
//   (error) => {
//     console.error('Response error:', {
//       url: error.config?.url,
//       status: error.response?.status,
//       data: error.response?.data,
//       message: error.message,
//     });
//     return Promise.reject(error);
//   },
// );

const get = (url, params = null, options = {}) =>
  client
    .get(url, {
      params,
      ...options,
      withCredentials: false,
    })
    .catch((error) => {
      if (error.code === 'ECONNABORTED') {
        console.log('Request aborted or timed out');
        return Promise.reject(error);
      }
      throw error;
    });

const post = (url, payload) => client.post(url, payload);

const put = (url, payload) => client.put(url, payload);

const del = (url) => client.delete(url);

const upload = (url, formData, onProgress) =>
  client.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (e) =>
      onProgress
        ? onProgress(Math.round((e.loaded * 100) / e.total))
        : () => {},
  });

export const getCurrentUser = () => get(`users/current`);

export const getCurrentLawFirm = () => get(`law-firms/current`);

export const updateCurrentLawFirm = (data) => put(`law-firms/current`, data);

export const getJobs = (filters) => {
  const params = { ...filters };

  // For Filevine, only remove project_id but keep lpms_id
  if (params.lpms === 'FILEVINE') {
    delete params.project_id;
  }

  console.log('Requesting jobs with params:', params);
  return get('jobs', params);
};

export const getUsers = (filters) => get(`users`, filters);

export const getStates = () => get(`states`);

export const getServePricings = (address_1, city, state_id, zip) =>
  get(`serve-pricings/address`, { address_1, city, state_id, zip });

/**
 * Gets service add-ons
 * @param sortBy 'amount' | 'description' | 'name' | 'server_cut'
 * @param sortDir 'asc' | 'desc'
 * @param triggers 'MAILING_FIRST_CLASS' | 'MAILING_CERTIFIED' | 'MAILING_PER_PAGE' | 'MAILING_OVER_PAGES' | 'JOB_CREATED_FILE_CASE' | 'JOB_CREATED_FILE_AFFIDAVIT'
 * @return {Promise<unknown>}
 */
export const getServiceAddOns = (sortBy, sortDir, triggers) => {
  let queryParams = '';
  triggers.forEach((trigger) => (queryParams += `triggers[]=${trigger}&`));

  return get(
    `service-add-on?sort_by=${sortBy}&sort_dir=${sortDir}&${queryParams}`,
  );
};

export const getJob = (jobID) => get(`jobs/${jobID}`);

export const getMessages = (jobID) => get(`jobs/${jobID}/messages`);

export const sendMessage = (jobID, message) =>
  post(`jobs/${jobID}/messages`, message);

export const readMessage = (jobID, messageID) =>
  post(`jobs/${jobID}/messages/${messageID}/read`);

export const cancelJob = (jobID, reason) =>
  post(`jobs/${jobID}/cancel`, { cancel_job_reason: reason });

export const flagJob = (jobID, flag) => post(`jobs/${jobID}/flags`, flag);

export const transferJob = (jobID, newOwnerID) =>
  post(`jobs/${jobID}/transfer`, { user_id: newOwnerID });

export const getClioExpenseCategories = () =>
  get(`lpms/clio/expense-categories`);

export const getClioDocumentCategories = () =>
  get(`lpms/clio/document-categories`);

export const getClioMatter = (matterID) => {
  console.log('Getting Clio matter with ID:', matterID);
  // Add validation for Clio matter IDs if possible
  return get(`lpms/clio/matters/${matterID}`);
};
export const getClioDocuments = (matterID) =>
  get(`lpms/clio/matters/${matterID}/documents`);

export const getClioFolders = (matterID) =>
  get(`lpms/clio/matters/${matterID}/folders`);

export const getClioRelationships = (matterID) =>
  get(`lpms/clio/matters/${matterID}/relationships`);

export const getClioMatterContacts = (matterID) =>
  get(`lpms/clio/matters/${matterID}/contacts`);

export const getLitifyMatter = (matterID) =>
  get(`lpms/litify/matters/${matterID}`);

export const getLitifyDocuments = (matterID) =>
  get(`lpms/litify/matters/${matterID}/documents`);

export const getLitifyMatterRoles = (matterID) =>
  get(`lpms/litify/matters/${matterID}/roles`);

export const getMyCaseCase = (caseID) => get(`lpms/mycase/cases/${caseID}`);

export const getMyCaseDocuments = (caseID) =>
  get(`lpms/mycase/cases/${caseID}/documents`);

export const getMyCaseClient = (clientID) =>
  get(`lpms/mycase/clients/${clientID}`);

export const getFilevineContacts = () => get(`lpms/filevine/contacts`);

export const createJob = (job) => {
  if (job.servees) {
    job.servees = job.servees.map((s) => ({
      ...s,
      same_address: s.same_address === false ? null : s.same_address,
    }));
  }
  return post(`jobs`, job);
};

export const setupIntent = () => {
  return post(`/billing/stripe/setup-intent`).then((response) => {
    // The response we're getting has the client_secret in the data property
    const data = response?.data || response;
    console.log('Setup intent full response:', response);
    console.log('Setup intent data:', data);

    if (!data?.client_secret) {
      console.error('Missing client_secret in setup intent response:', data);
    }
    return data; // Return the data object that contains client_secret
  });
};
export const getPaymentMethods = () => get(`billing/payment-methods`);

export const createPaymentMethod = (data) =>
  post(`billing/payment-methods`, data);

export const createAddress = (jobID, data) =>
  post(`jobs/${jobID}/servees/${jobID}/addresses`, data);

export const updateAddress = (jobID, addressID, data) =>
  put(`jobs/${jobID}/servees/${jobID}/addresses/${addressID}`, data);

export const deleteAddress = (jobID, addressID) =>
  del(`jobs/${jobID}/servees/${jobID}/addresses/${addressID}`);

export const getSpecialRequirements = (lawFirmId) =>
  get(`law-firms/${lawFirmId}/special-requirement`);

export const uploadDocument = (file, progress) => {
  const formData = new FormData();
  formData.append('file', file);
  return upload('documents', formData, progress);
};

export const uploadServeePhoto = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return upload('servee-photos', formData);
};

const FILEVINE_BASE_URL = '/lpms/filevine';
const PROJECTS_URL = `${FILEVINE_BASE_URL}/projects`;

export const getFilevineProject = (projectId) => {
  return get(`${PROJECTS_URL}/${projectId}`);
};

export const createLPMSDocument = async (params) => {
  return post(`${FILEVINE_BASE_URL}/documents`, params);
};
