import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import JobListScreen from 'screens/JobListScreen';
import { Routes, Route, MemoryRouter } from 'react-router-dom';
import JobDetailScreen from 'screens/JobDetailScreen';
import JobCreationScreen from 'screens/job-creation/JobCreationScreen';
import { client as axiosClient, getCurrentLawFirm } from 'utils/api';
import { CurrentUserContextProvider, CurrentUserContext } from 'context/CurrentUserContext';
import { MatterContextProvider, MatterContext } from 'context/MatterContext';
import SettingsScreen from 'screens/SettingsScreen';
import SupportScreen from 'screens/SupportScreen';
import { ToastProvider } from 'components/Toast';
import ErrorBoundary from 'components/ErrorBoundary';
import { getCookie } from 'utils/cookie';
import LoginScreen from 'screens/LoginScreen';
import EditFirmAddress from 'screens/modals/EditFirmAddress';
import { isFirmAddressFullyPopulated } from './utils/funcs';
import { Provider as UrqlProvider } from 'urql';
import { client as urqlClient } from 'graphql/client';
import Button from 'components/Button';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`;

const SignOutContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const AppRoutes = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={
        <ErrorBoundary name="JobListScreen">
          <JobListScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/jobs/new"
      element={
        <ErrorBoundary name="JobCreationScreen">
          <JobCreationScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/jobs/:jobID"
      element={
        <ErrorBoundary name="JobDetailScreen">
          <JobDetailScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/settings"
      element={
        <ErrorBoundary name="SettingsScreen">
          <SettingsScreen />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/support"
      element={
        <ErrorBoundary name="SupportScreen">
          <SupportScreen />
        </ErrorBoundary>
      }
    />
  </Routes>
);

const LoginRoutes = () => (
  <Routes>
    <Route exact path="/" element={<LoginScreen />} />
    <Route exact path="/support" element={<SupportScreen />} />
  </Routes>
);

const AppContent = ({ theme }) => {
  const { error } = useContext(MatterContext);
  const currentUser = useContext(CurrentUserContext);

  if (theme.id === 'FILEVINE') {
    if (error?.type === 'org_mismatch') {
      return (
        <div style={{ 
          padding: '2rem', 
          textAlign: 'center',
          maxWidth: '600px',
          margin: '0 auto'
        }}>
          <h2>Project Unavailable</h2>
          <p>{error.message}</p>
        </div>
      );
    }
    
    if (error?.type === 'not_integrated') {
      return (
        <div style={{ 
          padding: '2rem', 
          textAlign: 'center',
          maxWidth: '600px',
          margin: '0 auto'
        }}>
          <h2>Integration Not Configured</h2>
          <p>{error.message}</p>
          <SignOutContainer>
            <Button 
              variant={'secondary'} 
              onClick={() => {
                document.cookie = `${AUTH_TOKEN_COOKIE}=; path=/; domain=${COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                if (window.parent) {
                  window.parent.postMessage({ type: 'PROOF_LPMS_LOGOUT' }, '*');
                }
                window.location.reload();
              }}
            >
              Sign Out
            </Button>
          </SignOutContainer>
        </div>
      );
    }
  }

  return <AppRoutes />;
};

const App = ({ theme }) => {
  const [authToken, setAuthToken] = useState(null);
  const [firm, setFirm] = useState(null);
  const [loading, setLoading] = useState(true);

  // Use useCallback to stabilize these functions
  const handleFirmUpdate = useCallback((updates) => {
    setFirm((prev) => ({
      ...prev,
      ...updates,
    }));
  }, []);

  // Use useMemo for theme style
  const ThemeGlobalStyle = useMemo(() => theme.global, [theme]);

  // Consolidate auth token effect
  useEffect(() => {
    const cookieToken = getCookie(AUTH_TOKEN_COOKIE);
    if (cookieToken) {
      setAuthToken(cookieToken);
      axiosClient.defaults.headers['Authorization'] = cookieToken;
    }
    setLoading(false);
  }, []);

  // Separate firm loading effect
  useEffect(() => {
    if (authToken) {
      getCurrentLawFirm()
        .then(setFirm)
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [authToken]);

  // Early return for auth callback
  const params = new URLSearchParams(window.location.search);
  if (params.get('auth_callback')) {
    window.close();
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ThemeGlobalStyle />
        <ToastProvider>
          {!authToken ? (
            <MemoryRouter>
              <ErrorBoundary name="LoginRoutes">
                <LoginRoutes />
              </ErrorBoundary>
            </MemoryRouter>
          ) : (
            <CurrentUserContextProvider>
              <ErrorBoundary name="ContentArea">
                <MemoryRouter>
                  <ErrorBoundary name="EditFirmAddress">
                    <EditFirmAddress
                      open={firm && !isFirmAddressFullyPopulated(firm)}
                      firm={firm}
                      onSubmit={handleFirmUpdate}
                      onClose={handleFirmUpdate}
                    />
                  </ErrorBoundary>
                  <MatterContextProvider theme={theme}>
                    <UrqlProvider value={urqlClient}>
                      <AppContent theme={theme} />
                    </UrqlProvider>
                  </MatterContextProvider>
                </MemoryRouter>
              </ErrorBoundary>
            </CurrentUserContextProvider>
          )}
        </ToastProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
