import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Context object for adding extra information to logs. Can be used to add user information, etc.
 * @typedef {Record<string, unknown>} DDContext
 */

/**
 * Log an error to console and Datadog
 * @param {string} name - The name/title of the error
 * @param {Error} error - The error object
 * @param {DDContext} [messageContext] - Optional context object
 */
export const logError = (name, error, messageContext = {}) => {
  console.error(name, error, messageContext);
  datadogLogs.logger.error(name, messageContext, error);
  datadogRum.addError(error, {
    message: name,
    ...messageContext,
  });
};

/**
 * Log a warning to console and Datadog
 * @param {string} name - The name/title of the warning
 * @param {Error} error - The error object
 * @param {DDContext} [messageContext] - Optional context object
 */
export const logWarning = (name, error, messageContext) => {
  console.warn(name, error, messageContext);
  datadogLogs.logger.warn(name, messageContext, error);
};

/**
 * Log an error to RUM
 * @param {Error} error - The error object
 * @param {DDContext} [messageContext] - Optional context object
 */
export const logRUMError = (error, messageContext) => {
  console.error(error, messageContext);
  datadogRum.addError(error, messageContext);
};

/**
 * Log info message to console and Datadog
 * @param {string} name - The name/title of the info message
 * @param {DDContext} [messageContext] - Optional context object
 */
export const logInfo = (name, messageContext) => {
  console.debug(`DEBUG: ${name}`, messageContext);
  datadogLogs.logger.info(name, messageContext);
};
