import { formattedAddress } from 'domain/address';
import { canCancelJob, getNiceStatus } from 'domain/job';
import { getNiceFlagType, getFlagDetails } from 'domain/flags';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import styled from 'styled-components';
import { getJob, getMessages, readMessage, sendMessage } from 'utils/api';
import Pill from 'components/Pill';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { ChatMessages } from 'components/ChatMessages';
import { Table } from 'components/Table';
import { formatMoney } from 'utils/format';
import Moment from 'react-moment';
import { Link } from 'components/Link';
import { Accordion } from 'components/Accordion';
import Loader from 'components/Loader';
import TransferJobModal from 'screens/modals/TransferJobModal';
import FlagJobModal from 'screens/modals/FlagJobModal';
import CancelJobModal from 'screens/modals/CancelJobModal';
import AddAddressModal from 'screens/modals/AddAddressModal';
import DeleteAddressModal from 'screens/modals/DeleteAddressModal';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ServeMeta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const getLPMSTheme = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('lpms')?.toLowerCase();
};

const Body = styled.div`
  display: flex;
  flex-direction: ${() => (getLPMSTheme() === 'filevine' ? 'column' : 'row')};
  margin: ${() =>
    getLPMSTheme() === 'filevine' ? '0 0 -1.2rem 0' : '-0.6rem'};

  > * {
    margin: ${() =>
      getLPMSTheme() === 'filevine' ? '0 0 1.2rem 0' : '0.6rem'};
    flex: ${() => (getLPMSTheme() === 'filevine' ? 'none' : '1')};
    width: ${() => (getLPMSTheme() === 'filevine' ? '100%' : 'auto')};
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    margin-bottom: 1.2rem;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const InputContainer = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 2rem 2rem 2rem;

  button {
    margin-left: 1rem;
  }
`;

const Buttons = styled.div`
  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;
const PillContainer = styled.div`
  display: inline-block;
  margin-left: 1rem;
`;

const AttemptRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > :first-child {
    margin-right: 2rem;
  }
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const DetailRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > :first-child {
    margin-bottom: 1rem;
  }
`;

const DetailKey = styled.div`
  font-weight: 700;
  width: 20rem;
  flex-shrink: 0;
`;

const DetailKeySmall = styled.div`
  font-weight: 700;
  width: 12.5rem;
  flex-shrink: 0;
`;

const DetailValue = styled.div``;

const AttemptPhoto = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const JobDetailScreen = () => {
  const { jobID } = useParams();
  const navigate = useNavigate();
  const chatRef = useRef(null);

  const [job, setJob] = useState(null);
  const [flagJobModalOpen, setFlagJobModalOpen] = useState(false);
  const [cancelModalOpen, setCancelJobModalOpen] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [lastMessageCount, setLastMessageCount] = useState(0);
  const [transferJobModalOpen, setTransferJobModalOpen] = useState(false);

  const [addAddressModalOpen, setAddAddressModalOpenModalOpen] =
    useState(false);
  // const [editAddress, setEditAddress] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(false);

  const loadJob = () => {
    getJob(jobID)
      .then((j) => setJob(j))
      .catch(console.error);
  };

  useEffect(() => {
    loadJob();
  }, []);

  useEffect(() => {
    if (!job) {
      return;
    }

    // Initial load
    scrollChatToBottom();
    loadMessages();
  }, [job?.id]);

  if (!job) {
    return <Loader />;
  }

  const loadMessages = () => {
    // if this is a child job that is related to its parent job by same address,
    // use parent job ID for reading messages; otherwise use this job's ID;
    const jobId = job.same_address_job_id ?? jobID;

    getMessages(jobId)
      .then((messages) => {
        if (messages.length !== lastMessageCount) {
          setJob((prev) => ({ ...prev, messages }));
          readMessage(jobId, messages[messages.length - 1]?.id).catch(
            console.error,
          );
          setLastMessageCount(messages.length);
          scrollChatToBottom();
        }
      })
      .catch(console.error);
  };

  const scrollChatToBottom = () => {
    if (!chatRef || !chatRef.current) {
      return;
    }
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  };

  const submitMessage = (e) => {
    e.preventDefault();
    const trimmedMessage = chatMessage.trim();
    if (!trimmedMessage) {
      return;
    }

    // if this is a child job that is related to its parent job by same address,
    // use parent job ID for sending messages; otherwise use this job's ID;
    const jobId = job.same_address_job_id ?? jobID;

    sendMessage(jobId, {
      message: trimmedMessage,
    })
      .then(() => loadMessages())
      .catch(console.error);
    setChatMessage('');
  };

  const unresolvedFlags = job.flags?.filter((f) => !f.resolved) ?? [];

  return (
    <React.Fragment>
      <AddAddressModal
        open={addAddressModalOpen}
        job={job}
        onSubmit={() => {
          loadJob();
          setAddAddressModalOpenModalOpen(false);
        }}
        onClose={() => setAddAddressModalOpenModalOpen(false)}
      />
      {/*
        <EditAddressModal open={!!editAddress}
                          address={editAddress}
                          job={job}
                          onSubmit={() => {
                              loadJob();
                              setEditAddress(false);
                          }}
                          onClose={() => setEditAddress(false)}/>
*/}
      <DeleteAddressModal
        open={!!deleteAddress}
        address={deleteAddress}
        job={job}
        onSubmit={() => {
          loadJob();
          setDeleteAddress(false);
        }}
        onClose={() => setDeleteAddress(false)}
      />

      <CancelJobModal
        open={cancelModalOpen}
        job={job}
        onSubmit={() => {
          loadJob();
          setCancelJobModalOpen(false);
        }}
        onClose={() => setCancelJobModalOpen(false)}
      />
      <TransferJobModal
        open={transferJobModalOpen}
        job={job}
        onSubmit={() => {
          loadJob();
          setTransferJobModalOpen(false);
        }}
        onClose={() => setTransferJobModalOpen(false)}
      />
      <FlagJobModal
        open={flagJobModalOpen}
        job={job}
        onSubmit={() => {
          loadJob();
          setFlagJobModalOpen(false);
        }}
        onClose={() => setFlagJobModalOpen(false)}
      />
      <Button variant="secondary" onClick={() => navigate(-1)}>
        Go back
      </Button>
      <Header>
        <div>
          <h1>{job.servee.name}</h1>
          <ServeMeta>
            <Pill
              variant={
                {
                  UNASSIGNED: 'secondary',
                  PENDING_FILING: 'secondary',
                  ASSIGNED: 'primary',
                  IN_PROGRESS: 'primary',
                  PENDING_NOTARY: 'warning',
                  FILING_AFFIDAVIT: 'warning',
                  IN_REVIEW: 'success',
                  PENDING_RATING: 'success',
                  COMPLETED: 'success',
                  REASSIGNED: 'destructive',
                  CANCELED: 'destructive',
                }[job.status]
              }
            >
              {getNiceStatus(job)}
            </Pill>
            <Pill
              variant={
                {
                  Standard: 'primary',
                  Expedited: 'warning',
                  'Same Day': 'destructive',
                  Post: 'success',
                  Garnishment: 'info',
                  Envelope: 'info',
                }[job.serve_pricing?.name ?? 'primary']
              }
            >
              {job.serve_pricing?.name ?? ''}
            </Pill>
            {unresolvedFlags.map((f) => (
              <Pill key={f.id} title={getFlagDetails(f)} variant="warning">
                Flagged: {getNiceFlagType(f.type)}
              </Pill>
            ))}
          </ServeMeta>
        </div>
        <Buttons>
          <Button
            variant="secondary"
            to={`${APP_URL}jobs/${jobID}`}
            target="_blank"
          >
            Open Proof
          </Button>
          {['DRAFT', 'CANCELED'].indexOf(job.status) === -1 && (
            <Button
              variant="secondary"
              onClick={() => setTransferJobModalOpen(true)}
            >
              Transfer ownership
            </Button>
          )}
          {['DRAFT', 'CANCELED'].indexOf(job.status) === -1 && (
            <Button
              variant="secondary"
              onClick={() => setFlagJobModalOpen(true)}
            >
              Add flag
            </Button>
          )}
          {canCancelJob(job) && (
            <Button
              variant="destructive"
              onClick={() => setCancelJobModalOpen(true)}
            >
              Cancel serve request
            </Button>
          )}
          {job.signed_affidavit_url && (
            <Button
              target="_blank"
              to={job.signed_affidavit_url}
              variant="primary"
            >
              Download affidavit
            </Button>
          )}
          {job.invoice && (
            <Button
              target="_blank"
              to={API_URL + `invoices/${job.invoice.id}/pdf`}
              variant="primary"
            >
              Download invoice
            </Button>
          )}
        </Buttons>
      </Header>
      <Body>
        <Card title="Job Details">
          <DetailRow>
            <DetailKey>Job number</DetailKey>
            <DetailValue>{job.id}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Owner</DetailKey>
            <DetailValue>{job.user.name}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Server</DetailKey>
            <DetailValue>{job.server?.name ?? 'Unassigned'}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Proof Specialist</DetailKey>
            <DetailValue>{job.territory_manager?.name ?? 'N/A'}</DetailValue>
          </DetailRow>
          {job.original_job_id && (
            <DetailRow>
              <DetailKey>Original job</DetailKey>
              <DetailValue>
                <Link to={`/jobs/${job.original_job_id}`}>Link</Link>
              </DetailValue>
            </DetailRow>
          )}
          {job.new_job_id && (
            <DetailRow>
              <DetailKey>New job</DetailKey>
              <DetailValue>
                <Link to={`/jobs/${job.new_job_id}`}>Link</Link>
              </DetailValue>
            </DetailRow>
          )}
          <DetailRow>
            <DetailKey>Originated</DetailKey>
            <DetailValue>
              <Moment date={job.originated_at} format="L LT" />
            </DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Client reference number</DetailKey>
            <DetailValue>{job.billing_number ?? '—'}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Servee Type</DetailKey>
            <DetailValue>
              {job.servee.type === 'INDIVIDUAL' ? 'Individual' : 'Entity'}
            </DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Plaintiffs</DetailKey>
            <DetailValue>
              {job.plaintiffs.length > 0
                ? job.plaintiffs.map((p) => p.name).join('; ')
                : '—'}
            </DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailKey>Defendants</DetailKey>
            <DetailValue>
              {job.defendants.length > 0
                ? job.defendants.map((p) => p.name).join('; ')
                : '—'}
            </DetailValue>
          </DetailRow>
          {job.subpoena && (
            <DetailRow>
              <DetailKey>Subpoena</DetailKey>
              <DetailValue>{job.subpoena ? 'Yes' : 'No'}</DetailValue>
            </DetailRow>
          )}
          {job.subpoena && (
            <DetailRow>
              <DetailKey>Witness fee check</DetailKey>
              <DetailValue>
                {job.witness_fee
                  ? `Yes (${
                      isNaN(job.witness_fee)
                        ? job.witness_fee
                        : formatMoney(job.witness_fee)
                    })`
                  : 'No'}
              </DetailValue>
            </DetailRow>
          )}
          <DetailRow>
            <DetailKey>Base fee</DetailKey>
            <DetailValue>{formatMoney(job.base_fee)}</DetailValue>
          </DetailRow>
          {job.invoice && (
            <>
              <DetailRow>
                <DetailKey>Invoice number</DetailKey>
                <DetailValue>
                  <Link
                    target="_blank"
                    to={API_URL + `invoices/${job.invoice.id}/pdf`}
                  >
                    #{job.invoice.id}
                  </Link>
                </DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailKey>Invoice total</DetailKey>
                <DetailValue>{formatMoney(job.invoice.total)}</DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailKey>Invoice balance</DetailKey>
                <DetailValue>
                  {formatMoney(job.invoice.total - job.invoice.paid_amount)}
                </DetailValue>
              </DetailRow>
            </>
          )}
          {!!job.bounty_fee && (
            <DetailRow>
              <DetailKey>Success fee</DetailKey>
              <DetailValue>{formatMoney(job.bounty_fee)}</DetailValue>
            </DetailRow>
          )}
        </Card>
        <CardRow>
          <Card title="Attempts" noPadding={job.attempts.length !== 0}>
            {job.attempts.length === 0 && (
              <div>No attempts have been made on this serve request.</div>
            )}
            {job.attempts.map((attempt, i) => (
              <Accordion
                title={`Attempt #${i + 1}`}
                pill={
                  <Pill variant={attempt.success ? 'success' : 'destructive'}>
                    {attempt.success ? 'Successful' : 'Unsuccessful'}
                  </Pill>
                }
                key={attempt.id}
              >
                <AttemptRow>
                  <div>
                    <DetailRow>
                      <DetailKeySmall>Date</DetailKeySmall>
                      <DetailValue>
                        <Moment date={attempt.attempt_date} format="L LT" />
                      </DetailValue>
                    </DetailRow>
                    <DetailRow>
                      <DetailKeySmall>Address</DetailKeySmall>
                      <DetailValue>
                        <Link
                          to={`https://maps.google.com/?q=${formattedAddress(
                            attempt.address,
                          )}`}
                          target="_blank"
                        >
                          {formattedAddress(attempt.address)}
                        </Link>
                      </DetailValue>
                    </DetailRow>
                    {!!attempt.latitude && !!attempt.longitude && (
                      <DetailRow>
                        <DetailKeySmall>GPS</DetailKeySmall>
                        <DetailValue>
                          <Link
                            to={`http://maps.google.com/?q=${attempt.latitude},${attempt.longitude}`}
                            target="_blank"
                          >
                            {attempt.latitude}, {attempt.longitude}
                          </Link>
                        </DetailValue>
                      </DetailRow>
                    )}
                    {attempt.success && (
                      <DetailRow>
                        <DetailKeySmall>Service type</DetailKeySmall>
                        <DetailValue>{attempt.service_type}</DetailValue>
                      </DetailRow>
                    )}
                    {attempt.substitute_name && (
                      <DetailRow>
                        <DetailKeySmall>Substitute name</DetailKeySmall>
                        <DetailValue>{attempt.substitute_name}</DetailValue>
                      </DetailRow>
                    )}
                    {attempt.substitute_title && (
                      <DetailRow>
                        <DetailKeySmall>Substitute title</DetailKeySmall>
                        <DetailValue>{attempt.substitute_title}</DetailValue>
                      </DetailRow>
                    )}
                    {attempt.success && (
                      <DetailRow>
                        <DetailKeySmall>Served documents</DetailKeySmall>
                        <DetailValue>
                          {attempt.documents
                            .map(
                              (d) =>
                                job.documents.find((doc) => doc.id === d.id)
                                  .pivot.name,
                            )
                            .join(';')}
                        </DetailValue>
                      </DetailRow>
                    )}
                    <DetailRow>
                      <DetailKeySmall>Description</DetailKeySmall>
                      <DetailValue>{attempt.description}</DetailValue>
                    </DetailRow>
                  </div>
                  <div>
                    <DetailRowColumn>
                      <DetailKey>Photos</DetailKey>
                      <DetailValue>
                        {attempt.attempt_photos.length > 0
                          ? attempt.attempt_photos.map((photo) => (
                              <Link
                                key={photo.id}
                                target="_blank"
                                to={
                                  API_URL +
                                  `attempt-photos/${photo.id}.${photo.extension}`
                                }
                              >
                                <AttemptPhoto
                                  src={
                                    API_URL +
                                    `attempt-photos/${photo.id}.${photo.extension}`
                                  }
                                />
                              </Link>
                            ))
                          : 'None'}
                      </DetailValue>
                    </DetailRowColumn>
                  </div>
                </AttemptRow>
              </Accordion>
            ))}
          </Card>
          <Card
            title="Addresses"
            buttons={[
              <Button
                key="add-addr"
                variant="primary"
                size="small"
                onClick={() => setAddAddressModalOpenModalOpen(true)}
              >
                New address
              </Button>,
            ]}
          >
            <Table
              fields={[
                {
                  title: 'Address',
                  display: (row) => (
                    <>
                      {formattedAddress(row)}
                      {row.primary && (
                        <PillContainer>
                          <Pill variant="primary">Primary</Pill>
                        </PillContainer>
                      )}
                    </>
                  ),
                },
                {
                  title: 'Actions',
                  display: (row) => (
                    <Buttons>
                      {/*<Button variant={"secondary"} size={"small"} onClick={() => setEditAddress(row)}>Edit</Button>*/}
                      {!row.primary && (
                        <Button
                          variant="destructive"
                          size="small"
                          onClick={() => setDeleteAddress(row)}
                        >
                          Delete
                        </Button>
                      )}
                    </Buttons>
                  ),
                },
              ]}
              data={job.servee.servee_addresses}
            />
          </Card>
        </CardRow>
        <Card title="Chat" noPadding={true}>
          <ChatMessages messages={job.messages} bodyRef={chatRef} />
          <InputContainer onSubmit={submitMessage}>
            <Input
              placeholder="Send message..."
              value={chatMessage}
              onChange={(val) => setChatMessage(val)}
            />
            <Button variant="primary" type="submit">
              Send
            </Button>
          </InputContainer>
        </Card>
      </Body>
    </React.Fragment>
  );
};

export default JobDetailScreen;
