import { createClient, fetchExchange } from '@urql/core';
import { getCookie } from '../utils/cookie';

const client = createClient({
  url: `${API_URL}query`,
  exchanges: [fetchExchange],
  fetchOptions: () => {
    const token = getCookie(AUTH_TOKEN_COOKIE);
    if (!token) {
      console.warn('GraphQL Client: No auth token found');
    }
    
    return {
      credentials: 'same-origin',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': token || '',
      },
    };
  },
});

export { client }; 