import { gql } from '@urql/core';

export const createLPMSDocument = gql`
  mutation CreateLPMSDocument($input: CreateLPMSDocument!) {
    createLPMSDocument(input: $input) {
      id
      createdAt
      createdByID
      filename
      lawFirmID
      s3Filename
      size
    }
  }
`; 