import React from 'react';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { getDatadogConfig, getDatadogLogsConfig } from './config/datadog';
import { getLPMSTheme } from './utils/theme';
import App from './App';

const container = document.getElementById('app');
const root = ReactDOM.createRoot(container);

const theme = getLPMSTheme();

// Initialize Datadog RUM
datadogRum.init(getDatadogConfig());

// Initialize Datadog Logs
if (!DATADOG_RUM_DISABLE) {
  datadogLogs.init(getDatadogLogsConfig());
}

// Start tracking initial page view
datadogRum.startSessionReplayRecording();

root.render(<App theme={theme} />);

if (module.hot) {
  module.hot.accept();
}
