import { createGlobalStyle } from 'styled-components';
import { client } from 'graphql/client';
import { gql } from '@urql/core';

export const testGraphQLConnection = async () => {
  try {
    const result = await client
      .query(gql`
        query TestConnection {
          __schema {
            types {
              name
            }
          }
        }
      `)
      .toPromise();

    if (result.error) {
      throw result.error;
    }

    if (!result.data) {
      throw new Error('No data returned from GraphQL endpoint');
    }

    // TODO: Remove this after testing
    console.log('GraphQL Connection Test:', result);
    return result;
  } catch (error) {
    console.error('GraphQL Connection Error:', error?.message || error);
    throw error;
  }
};

export const filevine = {
  id: 'FILEVINE',
  name: 'Filevine',
  windowMessageModalSupport: true,
  matter: 'project',
  extension: true,
  global: createGlobalStyle`
    :root {
      --fv-font-family: "Helvetica Now Text", Helvetica, Arial, sans-serif;
      --fv-color-background: #f3f3ed;
      --fv-color-text: #333;
      --fv-spacing-base: 1.6rem;

      /* Page title specific variables */
      --fv-page-title-height: 7rem;
      --fv-page-title-background: var(--fv-white);
      --fv-page-title-border: var(--fv-gray-300);
      --fv-page-title-shadow: 0 1px 4px rgba(38, 50, 56, 0.2);

      /* Colors from Filevine */
      --fv-white: #fff;
      --fv-gray-300: #dee2e6;
      --fv-gray-600: #6c757d;
      --fv-brand-blue: #428bca;
      --fv-brand-red: #f24535;
      --fv-brand-yellow: #f1b000;
    }

    /* Base styles */
    html {
      font-size: 62.5%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    body {
      color: var(--fv-color-text);
      font-family: var(--fv-font-family);
      font-size: 1.4rem;
      line-height: 1.42857143;
      background-color: var(--fv-color-background);
      margin: 0;
      padding: 24px;
      height: 100%;
    }

    body.proof-extension iframe {
      width: 100%;
      height: calc(100% - 48px);
    }

    /* Page title styles */
    .fvs-page-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      min-height: var(--fv-page-title-height);
      padding: 2rem;
      background-color: var(--fv-page-title-background);
      border-bottom: 1px solid var(--fv-page-title-border);
      box-shadow: var(--fv-page-title-shadow);
    }

    /* Scrollbar styling */
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--fv-gray-600);
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: var(--fv-color-background);
      border-radius: 4px;
    }
  `,
  colors: {
    primary: '#428bca',
    secondary: '#6c757d',
    background: '#f3f3ed',
    surface: '#fff',
    border: '#dee2e6',
    text: '#333333',
    textSecondary: '#6c757d',
    accent: '#428bca',
    success: '#38A169',
    warning: '#f1b000',
    error: '#f24535',
    info: '#428bca',
    destructive: '#f24535',
    white: '#ffffff',
  },
  init: async () => {
    console.log('Initializing Filevine theme...');
    try {
      await testGraphQLConnection();
      console.log('GraphQL connection successful');
    } catch (error) {
      console.error('Failed to initialize GraphQL:', error);
    }
  },
};

// Call init after export
console.log('Loading Filevine theme...');
filevine.init().catch(console.error);
