import React, { createContext, useState, useEffect } from 'react';
import {
  getClioMatter,
  getLitifyMatter,
  getMyCaseCase,
  getFilevineProject,
} from 'utils/api';

const MatterContext = createContext({
  matter: null,
  loading: false,
  error: null,
});

const MatterContextProvider = ({ theme, children }) => {
  const [matter, setMatter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const matterID = params.get('matter_id') || params.get('project_id');

    if (!matterID) {
      console.log(
        'No matter_id or project_id found in URL params:',
        params.toString(),
      );
      setLoading(false);
      setError(new Error('No matter ID provided'));
      return;
    }

    if (!theme) {
      console.log('No theme provided to MatterContext');
      setLoading(false);
      setError(new Error('No theme provided'));
      return;
    }

    setLoading(true);
    setError(null);

    console.log(`Fetching ${theme.id} matter/project:`, {
      id: matterID,
      theme: theme.id,
      url: window.location.href,
      params: params.toString(),
    });

    const fetchMatter = async () => {
      try {
        let result;
        switch (theme.id) {
          case 'CLIO':
            result = await getClioMatter(matterID);
            break;
          case 'LITIFY':
            result = await getLitifyMatter(matterID);
            break;
          case 'MYCASE':
            result = await getMyCaseCase(matterID);
            break;
          case 'FILEVINE':
            result = await getFilevineProject(matterID);
            break;
          default:
            throw new Error(`Unsupported theme: ${theme.id}`);
        }
        console.log(`${theme.id} response:`, result);
        setMatter(result);
      } catch (err) {
        console.error(`Error fetching ${theme.id} data:`, {
          error: err,
          message: err.message,
          response: err.response?.data,
          status: err.response?.status
        });

        if (theme.id === 'FILEVINE') {
          if ((err.code === 'ERR_NETWORK' || err.response?.status === 500) && 
              err.config?.url?.includes('/filevine/projects/')) {
            setError({
              type: 'org_mismatch',
              message: 'The project you are trying to access belongs to a different Filevine org than the one currently linked to your Proof account. Proof currently supports integration with only one org per firm. For assistance, contact us at support@proofserve.com.'
            });
          }
          else if (err.response?.status === 401 && 
                   err.config?.url?.includes('/filevine/projects/')) {
            setError({
              type: 'not_integrated',
              message: 'It looks like the integration between your Proof account and Filevine is not yet set up. To get started, please visit app.proofserve.com/integrations and follow the setup instructions. For further assistance, contact us at support@proofserve.com.'
            });
          } else {
            setError(err);
          }
        } else {
          setError(err);
        }
        setMatter(null);
      } finally {
        setLoading(false);
      }
    };

    fetchMatter();
  }, [theme]);

  // Debug logging
  useEffect(() => {
    console.log('Matter updated:', matter);
  }, [matter]);

  useEffect(() => {
    console.log('Error updated:', error);
  }, [error]);

  useEffect(() => {
    console.log('Loading updated:', loading);
  }, [loading]);

  const contextValue = {
    matter,
    loading,
    error,
  };

  return (
    <MatterContext.Provider value={contextValue}>
      {children}
    </MatterContext.Provider>
  );
};
export { MatterContext, MatterContextProvider };
