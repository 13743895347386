import Button from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Input } from 'components/Input';
import Loader from 'components/Loader';
import Select from 'components/Select';
import { Link } from 'components/Link';
import { Table } from 'components/Table';
import { useToast } from 'components/Toast';
import { Required } from 'components/Required';
import { MatterContext } from 'context/MatterContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import AddPaymentMethodModal from 'screens/modals/AddPaymentMethodModal';
import AddSpecialRequirementsModal from 'screens/modals/AddSpecialRequirementsModal';
import styled, { css, withTheme } from 'styled-components';
import {
  createJob,
  getClioDocuments,
  getClioMatterContacts,
  getCurrentUser,
  getLitifyDocuments,
  getLitifyMatterRoles,
  getMyCaseDocuments,
  getMyCaseClient,
  getPaymentMethods,
  getServePricings,
  getSpecialRequirements,
  getStates,
  getUsers,
  uploadDocument,
} from 'utils/api';
import { createLPMSDocument } from 'graphql/mutations/document';
import { formatPercentage } from 'utils/format';
import { clio } from 'themes/clio';
import { litify } from 'themes/litify';
import { myCase } from 'themes/mycase';
import { filevine } from 'themes/filevine';
import { Mailing } from './Mailing';
import { ServeePhotos } from './ServeePhotos';
import { ServePricing } from './ServePricing';
import { client as urqlClient } from 'graphql/client';
import { useMutation } from 'urql';

const Root = styled.div`
  position: relative;
  padding-top: 4.8rem;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 2;
  padding: 0 2rem;
  height: 4.8rem;
  box-shadow: 0 1px 4px 0 rgb(38 50 56 / 20%);
  border-bottom: 1px solid #ccd9e0;

  h1 {
    color: #263238;
    font-size: 2rem;
    margin: 0;
  }
`;

const HeaderButtons = styled.div`
  > *:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const Form = styled.form`
  margin-top: 2rem;
  padding-bottom: 10rem;
`;

const variantSectionStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid #ddd;
        border-radius: 4px;
      `;
    case clio:
    default:
      return css``;
  }
};

export const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  ${({ theme, variant }) => variantSectionStyles(theme, variant)}
`;

const Errors = styled.ul`
  color: #e41b28;
  margin: auto;
  width: max-content;
`;

export const SectionHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const variantSectionHeaderStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        background: rgba(0, 0, 0, 0.03);
        font-size: 1.6rem;
        font-weight: 700;
        padding: 0.8rem 2rem;
        border-bottom: 1px solid #ddd;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      `;
    case clio:
    default:
      return css`
        margin-bottom: 1.2rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      `;
  }
};

export const SectionHeader = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  ${({ theme, variant }) => variantSectionHeaderStyles(theme, variant)}
`;

const variantSectionHeaderIconStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        display: none;
      `;
    case clio:
    default:
      return css`
        width: 3rem;
        height: 3rem;
        background: #abd8f6;
        border-radius: 50%;
        margin-right: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 1.4rem;
          width: auto;
        }
      `;
  }
};

export const SectionHeaderIcon = styled.div`
  ${({ theme, variant }) => variantSectionHeaderIconStyles(theme, variant)}
`;

const variantSectionBodyIconStyles = (theme) => {
  switch (theme) {
    case myCase:
      return css`
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      `;
    case clio:
    default:
      return css`
        border: 1px solid #e2ebee;
        box-shadow: 0 2px 4px rgb(0 70 112 / 5%);
      `;
  }
};

export const SectionBody = styled.div`
  background: #fff;
  padding: 2rem;
  ${({ theme, variant }) => variantSectionBodyIconStyles(theme, variant)}
`;

const AddServeeContainer = styled.div`
  margin-top: 1.2rem;
  text-align: right;
`;

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > * {
    flex: 1;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const PaymentMethodOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
`;

const AddPaymentMethodButton = styled.div`
  margin-right: 1.2rem;
`;

export const DocumentDropZone = styled.div`
  transition: border 200ms ease-in-out;
  border: 3px dashed ${({ active }) => (active ? '#006cd8' : '#e2ebee')};
  padding: 2rem;
  height: 10rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SubSectionTitle = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  margin-top: ${({ noMargin }) => (noMargin ? `0` : `3.6rem`)};
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InputOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    flex: 1;
    margin-bottom: 0 !important;
  }
`;

const DocumentsContainer = styled.div`
  margin-top: 2.4rem;
`;

const DocumentsMessage = styled.div`
  font-weight: 500;
`;

const InputOptionsDivider = styled.div`
  margin: 0 2rem;
  flex: 0;
  white-space: nowrap;
`;

const Addresses = styled.div`
  margin: 1.2rem 0;
`;

const Address = styled.div`
  border-bottom: 1px solid #e2ebee;
  padding: 2.4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > :first-child {
    flex: 1;
    padding-right: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const JobCreationScreenComponent = ({ theme }) => {
  const navigate = useNavigate();
  const matterContext = useContext(MatterContext);
  const { matter } = matterContext;
  const { addToast } = useToast();

  // State declarations
  const [documents, setDocuments] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [specialRequirements, setSpecialRequirements] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addPaymentMethodModalOpen, setAddPaymentMethodModalOpen] =
    useState(false);
  const [addSpecialRequirementsModalOpen, setAddSpecialRequirementsModalOpen] =
    useState(false);
  const [
    specialRequirementsDefaultCounts,
    setSpecialRequirementsDefaultCounts,
  ] = useState(0);
  const [addMailing, setAddMailing] = useState(false);

  // Initial job state
  const [job, setJob] = useState({
    billing_number: theme === filevine ? matter?.id?.toString() : '',
    lpms: theme.id,
    lpms_id: theme === filevine ? new URLSearchParams(window.location.search).get('project_id') : matter?.id?.toString() ?? '',
    case_number: '',
    user_id: null,
    state_id: null,
    court_date: null,
    pay_later: false,
    status: 'UNASSIGNED',
    source: theme.id,
    plaintiffs: [],
    defendants: [],
    documents: [],
    servees: [],
    special_requirements: [],
    file_case: false,
    file_affidavit: false,
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const j = { ...job };
        j.documents.push({
          id: null,
          filename: file.name,
          name: '',
          progress: 0,
        });
        setJob(j);
        uploadDocument(file, (progress) => {
          const j = { ...job };
          j.documents.find((d) => d.filename === file.name).progress = progress;
          setJob(j);
        })
          .then((f) => {
            const j = { ...job };
            const doc = j.documents.find((d) => d.filename === file.name);
            doc.key = f.document.id;
            doc.id = f.document.id;
            doc.progress = null;
            setJob(j);
          })
          .catch(console.error);
      });
    },
    [job],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const addServee = (e) => {
    if (e) {
      e.preventDefault();
    }

    const j = { ...job };
    if (theme === clio) {
      j.billing_number = matter?.client_reference ?? matter?.display_number;
    } else if (theme === litify) {
      j.billing_number = matter?.name;
      j.case_number = matter?.docket_number;
    } else if (theme === myCase) {
      j.billing_number = matter?.name;
      j.case_number = matter?.case_number;
    } else if (theme === filevine) {
      j.billing_number = matter?.id?.toString();
    }

    setJob({
      ...j,
      servees: [
        ...j.servees,
        {
          name: '',
          type: 'INDIVIDUAL',
          notes: '',
          subpoena: false,
          post_serve: false,
          authorize_skiptrace: false,
          serve_pricings: [],
          same_address: false,
          servee_addresses: [
            {
              primary: true,
              address_options: [],
              address_1: '',
              address_2: '',
              city: '',
              state_id: null,
              zip: '',
            },
          ],
          servee_photos: [],
          letter_trigger: 'NONE',
          letter_is_certified: false,
        },
      ],
    });
  };

  const deleteServee = (e, serveeIndex) => {
    e.preventDefault();
    const j = { ...job };
    j.servees.splice(serveeIndex, 1);
    j.servees = j.servees.map((servee) => ({
      ...servee,
      same_address:
        servee.same_address == serveeIndex
          ? true
          : servee.same_address > serveeIndex
            ? servee.same_address--
            : servee.same_address,
    }));
    setJob(j);
  };

  const addServeeAddress = (e, serveeIndex) => {
    if (e) {
      e.preventDefault();
    }
    const j = { ...job };
    j.servees[serveeIndex].servee_addresses.push({
      primary: false,
      address_options: [],
      address_1: '',
      address_2: '',
      city: '',
      state_id: null,
      zip: '',
    });
    setJob(j);
  };

  const deleteServeeAddress = (e, serveeIndex, addressIndex) => {
    e.preventDefault();
    const j = { ...job };
    const wasPrimary =
      j.servees[serveeIndex].servee_addresses[addressIndex].primary;
    j.servees[serveeIndex].servee_addresses.splice(addressIndex, 1);
    setJob(j);
    if (wasPrimary) {
      if (j.servees[serveeIndex].servee_addresses.length > 0) {
        setAddressField(serveeIndex, 0, 'primary', true);
      }
    }
  };

  const setServeeField = (serveeIndex, field, val, e) => {
    if (e) {
      e.preventDefault();
    }
    const j = { ...job };
    j.servees[serveeIndex][field] = val;
    let updateJob = true;

    if (field === 'prefill') {
      if (val === true || val === 'true') {
        // Reset fields when choosing to enter manually
        j.servees[serveeIndex].name = '';
        j.servees[serveeIndex].address_options = [];
        j.servees[serveeIndex].servee_addresses[0].prefill = true;
      } else {
        const contact = contacts.find((c) => c.id === val);

        // Set basic contact info
        j.servees[serveeIndex].name = contact.name;
        j.servees[serveeIndex].servee_addresses[0].prefill = null;

        if (theme === filevine) {
          // Filevine-specific handling
          j.servees[serveeIndex].type = contact.type === 'Individual' ? 'INDIVIDUAL' : 'ENTITY';

          if (contact.addresses && contact.addresses.length > 0) {
            const primaryAddress = contact.addresses[0];

            // Set primary address for Filevine
            j.servees[serveeIndex].servee_addresses[0] = {
              primary: true,
              address_1: primaryAddress.address1,
              address_2: primaryAddress.address2 || '',
              city: primaryAddress.city,
              state_id: primaryAddress.state_id,
              zip: primaryAddress.zip,
              type: 'HOME',
              lpms_id: `${contact.id}-${primaryAddress.address1}`,
            };

            // Format address options for Filevine
            j.servees[serveeIndex].address_options = contact.addresses.map(addr => ({
              id: `${contact.id}-${addr.address1}`,
              address_1: addr.address1,
              address_2: addr.address2 || '',
              city: addr.city,
              state_id: addr.state_id,
              province: addr.state,
              postal_code: addr.zip,
              type: 'HOME',
              name: 'Home'
            }));
          }
        } else if (theme === myCase) {
          // MyCase-specific handling
          j.servees[serveeIndex].address_options = [];
          updateJob = false;
          getMyCaseClient(contact.id).then((client) => {
            if (client.address) {
              j.servees[serveeIndex].address_options.push({
                id: client.id,
                address_1: client.address.address1,
                address_2: client.address.address2,
                city: client.address.city,
                province: client.address.state,
                postal_code: client.address.zip_code,
              });
              setJob(j);
            }
          });
        } else {
          // Clio and other providers - keep original simple assignment
          j.servees[serveeIndex].address_options = contact.addresses;
        }

        // Calculate pricing after address is set
        setTimeout(() => {
          reloadServePricings(serveeIndex);
        }, 0);
      }
    }

    if (updateJob) {
      setJob(j);
    }
  };

  const setAddressField = (serveeIndex, addressIndex, field, val) => {
    const j = { ...job };
    j.servees[serveeIndex].servee_addresses[addressIndex][field] = val;
    if (field === 'prefill') {
      if (val === true || val === 'true') {
        j.servees[serveeIndex].servee_addresses[addressIndex].type = '';
        j.servees[serveeIndex].servee_addresses[addressIndex].address_1 = '';
        j.servees[serveeIndex].servee_addresses[addressIndex].address_2 = '';
        j.servees[serveeIndex].servee_addresses[addressIndex].city = '';
        j.servees[serveeIndex].servee_addresses[addressIndex].state_id = '';
        j.servees[serveeIndex].servee_addresses[addressIndex].zip = '';
      } else {
        const address = j.servees[serveeIndex].address_options.find(
          (a) => a.id === val,
        );
        j.servees[serveeIndex].servee_addresses[addressIndex].type = {
          Work: 'WORK',
          Home: 'HOME',
          OTHER: 'OTHER',
        }[address.name];
        j.servees[serveeIndex].servee_addresses[addressIndex].lpms_id =
          address.id;
        if (address.address_1) {
          j.servees[serveeIndex].servee_addresses[addressIndex].address_1 =
            address.address_1;
          j.servees[serveeIndex].servee_addresses[addressIndex].address_2 =
            address.address_2 ?? '';
        } else {
          const streetSplit = address.street.split(/,|\n/);
          j.servees[serveeIndex].servee_addresses[addressIndex].address_1 =
            streetSplit[0];
          if (streetSplit.length > 1) {
            j.servees[serveeIndex].servee_addresses[addressIndex].address_2 =
              streetSplit[streetSplit.length - 1];
          }
        }
        j.servees[serveeIndex].servee_addresses[addressIndex].city =
          address.city;
        j.servees[serveeIndex].servee_addresses[addressIndex].state_id =
          states.find(
            (s) =>
              s.abbreviation.toLowerCase() === address.province.toLowerCase() ||
              s.name.toLowerCase() === address.province.toLowerCase(),
          )?.id;
        j.servees[serveeIndex].servee_addresses[addressIndex].zip =
          address.postal_code;
      }
    }
    if (field === 'primary' && val) {
      for (let i = 0; i < j.servees[serveeIndex].servee_addresses.length; i++) {
        if (
          i !== addressIndex &&
          j.servees[serveeIndex].servee_addresses[i].primary
        ) {
          j.servees[serveeIndex].servee_addresses[i].primary = false;
        }
      }
    }
    reloadServePricings(serveeIndex);
    setJob(j);
  };

  const reloadServePricings = (serveeIndex) => {
    const address = findPrimaryAddress(serveeIndex);
    console.log('Reloading serve pricings for address:', address);

    if (!address) {
      setServeeField(serveeIndex, 'tier', null);
      setServeeField(serveeIndex, 'serve_pricings', []);
      return;
    }

    if (
      !address.address_1 ||
      !address.city ||
      !address.state_id ||
      !address.zip
    ) {
      console.log('Missing required address fields:', address);
      setServeeField(serveeIndex, 'tier', null);
      setServeeField(serveeIndex, 'serve_pricings', []);
      return;
    }

    getServePricings(
      address.address_1,
      address.city,
      address.state_id,
      address.zip,
    )
      .then((res) => {
        console.log('Serve pricing response:', res);
        setServeeField(serveeIndex, 'tier', res.tier);
        setServeeField(serveeIndex, 'serve_pricings', res.pricings);

        // Auto-select first pricing option
        // if (res.pricings && res.pricings.length > 0) {
        //   setServeeField(serveeIndex, 'serve_pricing_id', res.pricings[0].id);
        // }
      })
      .catch((err) => {
        console.error('Error getting serve pricings:', err);
      });
  };

  const findPrimaryAddress = (serveeIndex) =>
    job.servees[serveeIndex].servee_addresses.find((a) => a.primary);

  const getValidationErrors = () => {
    const errors = {};

    // we expect documents to have either an ID for ones uploaded via drop zone
    // or `lpms` and `lpms_id` properties set for ones selected from dropdown
    const documents = job.documents.filter(
      (doc) => doc.id || (doc.lpms && doc.lpms_id),
    );

    documents.forEach((doc) => {
      if (!doc.filename.endsWith('.pdf')) {
        errors['documents[' + doc.lpms_id + ']'] =
          'Document ' +
          doc.filename +
          ' is invalid. Only PDF files are accepted.';
      }
      if (doc.progress) {
        errors['documents[' + doc.lpms_id + ']'] =
          'Document ' + doc.filename + ' is not finished uploading.';
      }
    });

    if (documents.length === 0) {
      errors['documents'] = 'Please upload at least one document to serve';
    }

    if (!job.billing_number) {
      errors['billing_number'] = 'Please enter a firm billing / matter number';
    }

    if (
      !job.stripe_card_id &&
      !job.pay_later &&
      !currentUser?.law_firm?.allow_post_payment
    ) {
      errors['stripe_card_id'] = 'Please choose a payment method';
    }

    if (!job.state_id) {
      errors['state_id'] = 'Please choose a court state';
    }

    for (let i = 0; i < job.servees.length; i++) {
      const servee = job.servees[i];
      const name = servee.name ? servee.name : 'Servee #' + (i + 1);
      if (!servee.type) {
        errors['servees[' + i + '].type'] =
          `Please choose whether or not ${name} is an individual or an entity`;
      }
      if (!servee.name) {
        errors['servees[' + i + '].name'] = `Please enter a name for ${name}`;
      }

      if (servee.same_address === false) {
        for (let j = 0; j < servee.servee_addresses.length; j++) {
          const address = servee.servee_addresses[j];
          if (
            !address.address_1 ||
            !address.city ||
            !address.state_id ||
            !address.zip ||
            !address.type
          ) {
            errors['servees[' + i + '].addresses[' + j + ']'] = `Address #${
              j + 1
            } for ${name} is invalid. Please make sure you enter the street, city, state, zip, and type.`;
          }
        }
      }

      if (servee.same_address === true) {
        errors['servees[' + i + '].same_address'] =
          `Please choose which previous servee address to use for ${name}`;
      }

      if (servee.same_address === false) {
        if (!servee.serve_pricing_id) {
          errors['servees[' + i + '].serve_pricing_id'] =
            `Please choose a serve speed for ${name}`;
        }
      } else if (servee.same_address !== true) {
        servee.serve_pricing_id =
          job.servees[servee.same_address].serve_pricing_id;
      }

      if (addMailing && servee.letter_trigger === 'NONE') {
        errors['mailing option'] = `Please choose a valid mailing option`;
      }
    }

    return errors;
  };

  const setServeeSameAddressIndex = (index, parentIndex) => {
    // FIXME: reduce state update calls to one
    setServeeField(index, 'same_address', parentIndex);
    setServeeField(
      index,
      'servee_addresses',
      job.servees[parentIndex].servee_addresses,
    );
    job.servees[index].servee_addresses.forEach((sa, j) =>
      setAddressField(index, j, 'id', null),
    );
  };

  const validSameAddressServees = (i) => {
    const valid = [];
    job.servees.forEach((s, sIndex) => {
      if (s.same_address === false && sIndex < i) {
        valid.push({
          label: s.name ? s.name : 'Servee #' + (sIndex + 1),
          value: sIndex.toString(),
        });
      }
    });
    return valid;
  };

  const submit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (loading) {
      return;
    }

    const errors = getValidationErrors();
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      addToast(
        'error',
        'Error',
        'Failed to submit serve request. Please fill out all required fields',
      );
      return;
    }

    setLoading(true);

    const submissionData = {
      ...job,
      lpms: theme.id,
      source: theme.id,
      lpms_id: theme === filevine ? new URLSearchParams(window.location.search).get('project_id') : matter.id.toString(),
      billing_number: job.billing_number,
      case_number: job.case_number || '',
      user_id: job.user_id,
      state_id: job.state_id,
      stripe_card_id: parseInt(job.stripe_card_id, 10),
      special_requirements: job.special_requirements || [],

      // Normalize servee data
      servees: job.servees.map((servee) => ({
        name: servee.name,
        type: servee.type,
        notes: servee.notes || '',
        subpoena: servee.subpoena || false,
        post_serve: servee.post_serve || false,
        authorize_skiptrace: servee.authorize_skiptrace || false,
        serve_pricing_id: servee.serve_pricing_id,
        same_address: servee.same_address || false,
        servee_addresses: servee.servee_addresses.map((addr) => ({
          primary: addr.primary,
          address_1: addr.address_1,
          address_2: addr.address_2 || '',
          city: addr.city,
          state_id: addr.state_id,
          zip: addr.zip,
          type: addr.type,
        })),
        letter_trigger: servee.letter_trigger || 'NONE',
        letter_is_certified: servee.letter_is_certified || false,
      })),

      // Normalize document data
      documents: job.documents.map((doc) => ({
        id: doc.id, // Make sure id is included in case manual upload
        lpms_id: doc.lpms_id, // Make sure lpms_id is included
        name: doc.name,
        filename: doc.filename,
        lpms: doc.lpms,
        ...(doc.lpms !== 'FILEVINE' && { lpms_id: doc.lpms_id }),
        pivot: { name: doc.name },
      })),
    };

    createJob(submissionData)
      .then((response) => {
        console.log('Job creation success:', response);
        setLoading(false);
        navigate('/');
      })
      .catch((err) => {
        console.error('Job creation error details:', err.response?.data);
        setLoading(false);
        addToast(
          'error',
          'Error',
          err.response?.data?.message || 'Error creating serve request',
        );
      });
  };
  const listSpecialRequirements = () =>
    specialRequirements.map((e) => ({
      value: e,
      label: e.title,
    }));

  // When a document is selected for Filevine, we need to handle it differently
  const handleFilevineDocumentSelection = async (doc) => {
    try {
      const result = await urqlClient.mutation(createLPMSDocument, {
        input: {
          documentId: doc.id,
          documentName: doc.name || doc.filename,
          lpmsType: 'FILEVINE'
        }
      }).toPromise();

      if (result.error) throw result.error;

      setJob({
        ...job,
        documents: [
          ...job.documents,
          {
            lpms: 'FILEVINE',
            key: doc.id.toString(),
            id: parseInt(result.data.createLPMSDocument.id, 10),
            name: doc.name || doc.filename,
            filename: doc.filename || doc.name,
          },
        ],
      });

    } catch (error) {
      console.error('Failed to create LPMS document:', error);
      addToast(
        'error',
        'Error',
        'Failed to import document from Filevine. Please try again.'
      );
    }
  };

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Get current user first since other logic depends on it
    getCurrentUser()
      .then((response) => {
        setCurrentUser(response);
        // Get special requirements after we have currentUser
        if (response?.law_firm?.id) {
          getSpecialRequirements(response.law_firm.id)
            .then((sr) => {
              setSpecialRequirements(sr);
              const defaults = sr.filter((req) => req.is_default);
              setSpecialRequirementsDefaultCounts(defaults.length);
              setJob((prev) => ({
                ...prev,
                special_requirements: defaults,
              }));
            })
            .catch(console.error);
        }
      })
      .catch(console.error);

    // Get users for all themes
    getUsers()
      .then((response) => {
        console.log('Users response:', response);
        setUsers(response);
      })
      .catch(console.error);

    // Theme-specific document and contact fetching
    if (theme === clio) {
      getClioDocuments(matter.id)
        .then((docs) =>
          setDocuments(
            [...documents].concat(
              docs.map((d) => ({
                ...d,
                lpms: true,
              })),
            ),
          ),
        )
        .catch(console.error);
      getClioMatterContacts(matter.id)
        .then((c) => setContacts(c))
        .catch(console.error);
    } else if (theme === litify) {
      getLitifyDocuments(matter.id)
        .then((docs) =>
          setDocuments(
            [...documents].concat(
              docs.map((d) => ({
                ...d,
                lpms: true,
              })),
            ),
          ),
        )
        .catch(console.error);
      getLitifyMatterRoles(matter.id)
        .then((c) => setContacts(c))
        .catch(console.error);
    } else if (theme === myCase) {
      getMyCaseDocuments(matter.id)
        .then((docs) =>
          setDocuments(
            [...documents].concat(
              docs.map((d) => ({
                ...d,
                lpms: true,
              })),
            ),
          ),
        )
        .catch(console.error);
      setContacts(
        matter.clients.map((c) => ({
          id: c.id,
          name: `${c.first_name} ${c.last_name}`,
          relationship: c.contact_group
            ? {
                description: c.contact_group,
              }
            : null,
          addresses: c.address
            ? []
            : [
                {
                  address_1: c.address1,
                  address_2: c.address1,
                  city: c.city,
                  province: c.state,
                  postal_code: c.zip_code,
                },
              ],
        })),
      );
    } else if (theme === filevine) {
      // Handle Filevine documents
      if (matter?.documents) {
        setDocuments(
          matter.documents.map((d) => ({
            ...d,
            lpms: true,
            id: d.id,
            filename: d.filename || d.name,
            name: d.name || d.filename || 'Unnamed document',
          })),
        );
      } else {
        // Initialize with empty array if no documents
        setDocuments([]);
      }

      if (matter?.defendants) {
        setContacts(matter.defendants.map(defendant => ({
          id: defendant.id || `defendant-${defendant.name}`,
          name: defendant.name,
          type: 'Individual',
          addresses: (defendant.addresses || []).map(addr => ({
            id: addr.id || `${defendant.name}-${addr.address1}`,
            address1: addr.address1,
            address2: addr.address2 || '',
            city: addr.city,
            state_id: addr.state_id,
            zip: addr.zip,
            state: addr.state
          })),
          relationship: { description: 'Defendant' }
        })));
      }
    }

    // Get states and other common data
    getStates()
      .then((s) => setStates(s))
      .catch(console.error);

    getPaymentMethods()
      .then((p) => setPaymentMethods(p))
      .catch(console.error);

    addServee();
  }, [matter?.id, theme]);

  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  useEffect(() => {
    if (!job || !currentUser || !paymentMethods || defaultPaymentMethod) {
      return;
    }
    if (
      job.stripe_card_id &&
      !paymentMethods.find((p) => p.id === job.stripe_card_id)
    ) {
      setJob({ ...job, stripe_card_id: null });
    }
    if (paymentMethods.length > 0) {
      let defaultMethod = paymentMethods.find((p) => p.default);
      if (!defaultMethod) {
        defaultMethod = paymentMethods[0];
      }
      setDefaultPaymentMethod(defaultMethod.id);
      setJob({ ...job, pay_later: false, stripe_card_id: defaultMethod.id });
    }
  }, [paymentMethods, currentUser, job]);

  const courtStateFilingProfileType = useCallback(
    () =>
      states?.find((s) => s?.id === job?.state_id)?.filing_profile_type ??
      'DISABLED',
    [job?.state_id],
  );

  if (!job || loading || !matter || !states || !documents) {
    console.log('Loading because:', {
      noJob: !job,
      loading,
      noMatter: !matter,
      noCurrentUser: !currentUser,
      noStates: !states,
      noDocuments: !documents,
    });
    return <Loader />;
  }

  return (
    <Root>
      <AddPaymentMethodModal
        paymentMethods={paymentMethods}
        open={addPaymentMethodModalOpen}
        onReload={() =>
          getPaymentMethods()
            .then((p) => setPaymentMethods(p))
            .catch(console.error)
        }
        onClose={() => {
          setAddPaymentMethodModalOpen(false);
          getPaymentMethods()
            .then((p) => setPaymentMethods(p))
            .catch(console.error);
        }}
      />
      <AddSpecialRequirementsModal
        defaultsCount={specialRequirementsDefaultCounts}
        open={addSpecialRequirementsModalOpen}
        onClose={(result) => {
          if (result) {
            specialRequirements.push(result);
            job.special_requirements.push(result);
            if (result.is_default) {
              setSpecialRequirementsDefaultCounts(
                specialRequirementsDefaultCounts + 1,
              );
            }
          }
          setAddSpecialRequirementsModalOpen(false);
        }}
      />
      <Header>
        <h1>Create job</h1>
        <HeaderButtons>
          <Button variant="primary" onClick={() => submit()}>
            Submit serve request
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </HeaderButtons>
      </Header>
      <Form onSubmit={submit}>
        {Object.values(errors).length > 0 && (
          <Errors>
            {Object.values(errors).map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </Errors>
        )}
        <Section>
          <SectionHeader>
            <SectionHeaderTitle>
              <SectionHeaderIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="#000"
                >
                  <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" />
                </svg>
              </SectionHeaderIcon>
              Documents
            </SectionHeaderTitle>
          </SectionHeader>
          <SectionBody>
            <SubSectionTitle noMargin={true}>
              What documents would you like served?
            </SubSectionTitle>
            <InputOptions>
              <InputRow>
                <Label>Choose documents from {theme.name}</Label>
                <Select
                  options={documents
                    .filter(
                      (d) =>
                        d.lpms &&
                        d.filename.toLowerCase().endsWith('.pdf') &&
                        !job.documents.find((jd) => jd.lpms_id == d.id),
                    )
                    .map((d) => ({
                      value: d.id,
                      label:
                        d.name +
                        (d.filename && d.name !== d.filename
                          ? ` (${d.filename})`
                          : ``),
                    }))}
                  value={null}
                  onChange={(val) => {
                    const doc = documents.find((d) => d.id === val);
                    // Only use special handling for Filevine
                    if (theme === filevine) {
                      handleFilevineDocumentSelection(doc);
                    } else {
                      // Use original simple document handling for Clio and others
                      setJob({
                        ...job,
                        documents: [
                          ...job.documents,
                          {
                            lpms: theme.id,
                            key: doc.id.toString(),
                            lpms_id: doc.id.toString(),
                            name: doc.name,
                            filename: doc.filename,
                          },
                        ],
                      });
                    }
                  }}
                />
              </InputRow>
              <InputOptionsDivider>— and/or —</InputOptionsDivider>
              <InputRow>
                <Label>Upload documents from your computer</Label>
                <DocumentDropZone {...getRootProps()} active={isDragActive}>
                  <input {...getInputProps()} />
                  <div>
                    Drag 'n' drop files from your computer, or{' '}
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={(e) => e.preventDefault()}
                    >
                      Click here to upload
                    </Button>
                  </div>
                </DocumentDropZone>
              </InputRow>
            </InputOptions>
            <DocumentsContainer>
              {job.documents.length === 0 && (
                <DocumentsMessage>
                  Please choose at least one document to serve above.
                </DocumentsMessage>
              )}
              {job.documents.length > 0 && (
                <Table
                  fields={[
                    {
                      title: 'Filename',
                      field: 'filename',
                    },
                    {
                      title: 'Document name',
                      field: 'name',
                      display: (row) =>
                        row.progress ? (
                          `Uploading: ${formatPercentage(row.progress / 100.0)}`
                        ) : (
                          <Input
                            type="text"
                            onChange={(val) => {
                              const j = { ...job };
                              j.documents.find((d) => d.key === row.key).name =
                                val;
                              setJob(j);
                            }}
                            value={row.name}
                          />
                        ),
                    },
                    {
                      title: '',
                      display: (row) => (
                        <Button
                          variant="destructive"
                          onClick={(e) => {
                            e.preventDefault();
                            const j = { ...job };
                            j.documents = j.documents.filter(
                              (d) => d.key !== row.key,
                            );
                            setJob(j);
                          }}
                        >
                          Remove
                        </Button>
                      ),
                    },
                  ]}
                  data={job.documents}
                />
              )}
            </DocumentsContainer>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>
            <SectionHeaderTitle>
              <SectionHeaderIcon>
                <svg viewBox="0 -256 1850 1850" fill="#000">
                  <g transform="matrix(1,0,0,-1,37.966102,1313.0508)">
                    <path d="m 640,1152 h 512 v 128 H 640 V 1152 z M 1792,512 V 32 q 0,-66 -47,-113 -47,-47 -113,-47 H 160 Q 94,-128 47,-81 0,-34 0,32 V 512 H 672 V 352 q 0,-26 19,-45 19,-19 45,-19 h 320 q 26,0 45,19 19,19 19,45 v 160 h 672 z m -768,0 V 384 H 768 v 128 h 256 z m 768,480 V 608 H 0 v 384 q 0,66 47,113 47,47 113,47 h 352 v 160 q 0,40 28,68 28,28 68,28 h 576 q 40,0 68,-28 28,-28 28,-68 v -160 h 352 q 66,0 113,-47 47,-47 47,-113 z" />
                  </g>
                </svg>
              </SectionHeaderIcon>
              Job details
            </SectionHeaderTitle>
          </SectionHeader>
          <SectionBody>
            <InputRow>
              <Label>
                Client reference number
                <Required />
              </Label>
              <Input
                type="text"
                value={job.billing_number}
                onChange={(val) => setJob({ ...job, billing_number: val })}
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>Case number</Label>
              <Input
                type="text"
                value={job.case_number}
                onChange={(val) => setJob({ ...job, case_number: val })}
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                Job owner
                <Required />
              </Label>
              <Select
                options={users.map((s) => {
                  return { value: s.id, label: s.name };
                })}
                placeholder="Choose an owner..."
                value={job.user_id}
                onChange={(val) => setJob({ ...job, user_id: val })}
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                Court state
                <Required />
              </Label>
              <Select
                options={states.map((s) => ({ value: s.id, label: s.name }))}
                placeholder="Choose a state..."
                value={job.state_id}
                onChange={(val) =>
                  setJob({
                    ...job,
                    file_case: false,
                    file_affidavit: false,
                    state_id: val,
                  })
                }
                theme={theme}
              />
            </InputRow>
            <SubSectionTitle>
              How would you like to pay?
              <Required />
            </SubSectionTitle>
            <InputOptions>
              <InputRow>
                <Label>
                  Choose payment method (automatically charged upon job
                  completion)
                </Label>
                <Select
                  options={paymentMethods.map((p) => ({
                    value: p.id,
                    label: `${p.label} (${p.brand} ending ${p.last_4})`,
                  }))}
                  placeholder="Choose a payment method..."
                  value={job.stripe_card_id}
                  onChange={(val) => setJob({ ...job, stripe_card_id: val })}
                  theme={theme}
                />
                <PaymentMethodOptions>
                  {theme !== filevine && (
                    <AddPaymentMethodButton>
                      <Button
                        size="small"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          setAddPaymentMethodModalOpen(true);
                        }}
                      >
                        Add payment method
                      </Button>
                    </AddPaymentMethodButton>
                  )}
                  <Link
                    target="_blank"
                    to={APP_URL + `billing?tab=payment_methods`}
                  >
                    Manage Payment Methods
                  </Link>
                </PaymentMethodOptions>
              </InputRow>
            </InputOptions>
            <SubSectionTitle>
              <Label>Special requirements</Label>
            </SubSectionTitle>
            <InputRow>
              <Select
                multi={true}
                placeholder="Select special requirements..."
                value={job.special_requirements}
                onChange={(val) =>
                  setJob({ ...job, special_requirements: val })
                }
                options={listSpecialRequirements()}
                theme={theme}
              />
            </InputRow>
            <div>
              Need to add a new special requirement?
              <Button
                size="small"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setAddSpecialRequirementsModalOpen(true);
                }}
              >
                Click here
              </Button>
            </div>
            <Link target="_blank" to={APP_URL + `admin?tab=job_preferences`}>
              Manage Requirements
            </Link>
            {currentUser?.law_firm?.efile_enabled &&
              courtStateFilingProfileType() !== 'DISABLED' && (
                <>
                  <SubSectionTitle>
                    <Label>E-File options</Label>
                  </SubSectionTitle>
                  <InputRow>
                    <Checkbox
                      title="File Case"
                      value={job.file_case}
                      onChange={(val) => setJob({ ...job, file_case: val })}
                      theme={theme}
                    />
                  </InputRow>
                  {(courtStateFilingProfileType() === 'FILE_FIRST' ||
                    courtStateFilingProfileType() ===
                      'FILE_FIRST_CITATION') && (
                    <InputRow>
                      <Checkbox
                        title="File Affidavit"
                        value={job.file_affidavit}
                        onChange={(val) =>
                          setJob({ ...job, file_affidavit: val })
                        }
                        theme={theme}
                      />
                    </InputRow>
                  )}
                </>
              )}
          </SectionBody>
        </Section>
        {job.servees.map((servee, i) => (
          <Section key={i}>
            <SectionHeader>
              <SectionHeaderTitle>
                <SectionHeaderIcon>
                  <svg version="1.1" viewBox="0 -256 1792 1792" fill="#000">
                    <g transform="matrix(1,0,0,-1,197.42373,1300.6102)">
                      <path d="M 1408,131 Q 1408,11 1335,-58.5 1262,-128 1141,-128 H 267 Q 146,-128 73,-58.5 0,11 0,131 0,184 3.5,234.5 7,285 17.5,343.5 28,402 44,452 q 16,50 43,97.5 27,47.5 62,81 35,33.5 85.5,53.5 50.5,20 111.5,20 9,0 42,-21.5 33,-21.5 74.5,-48 41.5,-26.5 108,-48 Q 637,565 704,565 q 67,0 133.5,21.5 66.5,21.5 108,48 41.5,26.5 74.5,48 33,21.5 42,21.5 61,0 111.5,-20 50.5,-20 85.5,-53.5 35,-33.5 62,-81 27,-47.5 43,-97.5 16,-50 26.5,-108.5 10.5,-58.5 14,-109 Q 1408,184 1408,131 z m -320,893 Q 1088,865 975.5,752.5 863,640 704,640 545,640 432.5,752.5 320,865 320,1024 320,1183 432.5,1295.5 545,1408 704,1408 863,1408 975.5,1295.5 1088,1183 1088,1024 z" />
                    </g>
                  </svg>
                </SectionHeaderIcon>
                {servee.name ? `Servee: ${servee.name}` : `Servee #${i + 1}`}
              </SectionHeaderTitle>
              {job.servees.length > 1 && (
                <Button
                  variant="destructive"
                  size="small"
                  onClick={(e) => deleteServee(e, i)}
                >
                  Remove servee
                </Button>
              )}
            </SectionHeader>
            <SectionBody>
              <InputRow>
                <Label>Who would you like to serve?</Label>
                <Select
                  options={(Array.isArray(contacts) ? contacts : [])
                    .filter((c) => c && c.name) // Only include contacts with names
                    .map((c) => ({
                      value: c.id,
                      label:
                        c.name +
                        (c.relationship?.description
                          ? ` (${c.relationship.description})`
                          : ''),
                    }))
                    .concat([
                      {
                        value: true,
                        label:
                          contacts.length > 0
                            ? 'I need to serve someone not listed above'
                            : 'Enter details manually (No Filevine contacts found)',
                      },
                    ])}
                  placeholder="Choose a contact..."
                  value={servee.prefill}
                  onChange={(val) => setServeeField(i, 'prefill', val)}
                  theme={theme}
                />
              </InputRow>
              {!!servee.prefill && (
                <>
                  <InputRow>
                    <Label>
                      Name of person to be served
                      <Required />
                    </Label>
                    <Input
                      type="text"
                      value={servee.name}
                      onChange={(val) => setServeeField(i, 'name', val)}
                      theme={theme}
                    />
                  </InputRow>
                  <InputRow>
                    <Label>
                      Servee type
                      <Required />
                    </Label>
                    <Select
                      options={[
                        {
                          value: 'INDIVIDUAL',
                          label: 'Individual',
                        },
                        {
                          value: 'ENTITY',
                          label: 'Entity',
                        },
                      ]}
                      placeholder="Choose a type..."
                      value={servee.type}
                      onChange={(val) => setServeeField(i, 'type', val)}
                      theme={theme}
                    />
                  </InputRow>
                  <ServeePhotos
                    serveeIndex={i}
                    servee={servee}
                    setServeeField={setServeeField}
                  />
                  <InputRow>
                    <Checkbox
                      title="Preauthorize skip trace for bad address ($75)"
                      value={servee.authorize_skiptrace}
                      onChange={(val) =>
                        setServeeField(i, 'authorize_skiptrace', val)
                      }
                      theme={theme}
                    />
                  </InputRow>
                  <InputRow>
                    <Checkbox
                      title="This is a subpoena"
                      value={servee.subpoena}
                      onChange={(val) => setServeeField(i, 'subpoena', val)}
                      theme={theme}
                    />
                  </InputRow>
                  {servee.subpoena && (
                    <>
                      <InputRow>
                        <Checkbox
                          title="Witness fee required (10% convenience charge, $2 min., $25 max.)"
                          value={servee.witness_fee_required}
                          onChange={(val) =>
                            setServeeField(i, 'witness_fee_required', val)
                          }
                          theme={theme}
                        />
                      </InputRow>
                      {servee.witness_fee_required && (
                        <InputRow>
                          <Label>
                            Witness fee check amount (enter dollar amount or
                            "calculate it for me")
                          </Label>
                          <Input
                            type="text"
                            value={servee.witness_fee}
                            onChange={(val) =>
                              setServeeField(i, 'witness_fee', val)
                            }
                            theme={theme}
                          />
                        </InputRow>
                      )}
                    </>
                  )}
                  <SubSectionTitle>
                    Where should we attempt to serve{' '}
                    {servee.name ?? `Servee #${i + 1}`}?
                  </SubSectionTitle>
                  {i > 0 && (
                    <>
                      <InputRow>
                        <Checkbox
                          title={`Same address as previous servee${
                            !currentUser.law_firm.same_address_discount_enabled
                              ? ''
                              : ' (50% discount)'
                          }`}
                          checked={servee.same_address !== false}
                          onChange={(val) =>
                            setServeeField(i, 'same_address', val)
                          }
                        />
                      </InputRow>
                      {servee.same_address !== false && (
                        <InputRow>
                          <Select
                            options={validSameAddressServees(i)}
                            placeholder="Select previous servee"
                            value={servee.same_address}
                            isMulti={false}
                            onChange={(value) => {
                              setServeeSameAddressIndex(i, value);
                              job.servees.forEach((s, j) => {
                                if (s.same_address == i) {
                                  setServeeSameAddressIndex(j, value);
                                }
                              });
                            }}
                            theme={theme}
                          />
                        </InputRow>
                      )}
                    </>
                  )}
                  {servee.same_address === false && (
                    <Addresses>
                      {servee.servee_addresses.map((address, j) => (
                        <Address key={i}>
                          <div>
                            <InputRow>
                              <Label>
                                Populate from existing address on file for
                                contact
                              </Label>
                              <Select
                                options={servee.address_options
                                  // filter out addresses that are already in use
                                  .filter(
                                    (a) =>
                                      address.lpms_id === a.id ||
                                      servee.servee_addresses.filter(
                                        (b) => a.id === b.lpms_id,
                                      ).length === 0,
                                  )
                                  .map((c) => ({
                                    value: c.id,
                                    label:
                                      `${
                                        c.street
                                          ? c.street
                                          : c.address_1 +
                                            (c.address_2
                                              ? `${c.address_2}, `
                                              : '')
                                      }, ${c.city}, ${c.province}, ${
                                        c.postal_code
                                      }` + (c.name ? ` (${c.name})` : ''),
                                  }))
                                  .concat([
                                    {
                                      value: true,
                                      label:
                                        'Enter a different address manually',
                                    },
                                  ])}
                                placeholder="Choose an address..."
                                value={address.prefill}
                                onChange={(val) =>
                                  setAddressField(i, j, 'prefill', val)
                                }
                                theme={theme}
                              />
                            </InputRow>
                            {!!address.prefill && (
                              <>
                                <InputGroup>
                                  <InputRow>
                                    <Label>Type</Label>
                                    <Select
                                      options={[
                                        { value: 'HOME', label: 'Home' },
                                        { value: 'WORK', label: 'Work' },
                                        {
                                          value: 'REGISTERED_AGENT',
                                          label: 'Registered Agent',
                                        },
                                        { value: 'ENTITY', label: 'Entity' },
                                        { value: 'OTHER', label: 'Other' },
                                      ]}
                                      placeholder="Choose a type..."
                                      value={address.type}
                                      onChange={(val) =>
                                        setAddressField(i, j, 'type', val)
                                      }
                                      theme={theme}
                                    />
                                  </InputRow>
                                  <InputRow>
                                    <Label>Address 1</Label>
                                    <Input
                                      type="text"
                                      value={address.address_1}
                                      onChange={(val) =>
                                        setAddressField(i, j, 'address_1', val)
                                      }
                                      theme={theme}
                                    />
                                  </InputRow>
                                  <InputRow>
                                    <Label>Address 2</Label>
                                    <Input
                                      type="text"
                                      value={address.address_2}
                                      onChange={(val) =>
                                        setAddressField(i, j, 'address_2', val)
                                      }
                                      theme={theme}
                                    />
                                  </InputRow>
                                </InputGroup>
                                <InputGroup>
                                  <InputRow>
                                    <Label>City</Label>
                                    <Input
                                      type="text"
                                      value={address.city}
                                      onChange={(val) =>
                                        setAddressField(i, j, 'city', val)
                                      }
                                      theme={theme}
                                    />
                                  </InputRow>
                                  <InputRow>
                                    <Label>State</Label>
                                    <Select
                                      options={states.map((s) => ({
                                        value: s.id,
                                        label: s.name,
                                      }))}
                                      placeholder="Choose a state..."
                                      value={address.state_id}
                                      onChange={(val) =>
                                        setAddressField(i, j, 'state_id', val)
                                      }
                                      theme={theme}
                                    />
                                  </InputRow>
                                  <InputRow>
                                    <Label>Zip</Label>
                                    <Input
                                      type="text"
                                      value={address.zip}
                                      onChange={(val) =>
                                        setAddressField(i, j, 'zip', val)
                                      }
                                      theme={theme}
                                    />
                                  </InputRow>
                                </InputGroup>
                                <Checkbox
                                  title="Primary address"
                                  value={address.primary}
                                  onChange={(val) =>
                                    setAddressField(i, j, 'primary', val)
                                  }
                                  theme={theme}
                                />
                              </>
                            )}
                          </div>
                          {servee.servee_addresses.length > 1 && (
                            <Button
                              variant="destructive"
                              size="small"
                              onClick={(e) => deleteServeeAddress(e, i, j)}
                            >
                              Remove address
                            </Button>
                          )}
                        </Address>
                      ))}
                      <Button
                        variant="primary"
                        onClick={(e) => addServeeAddress(e, i)}
                      >
                        Add additional address
                      </Button>
                    </Addresses>
                  )}
                  <SubSectionTitle>Additional Notes</SubSectionTitle>
                  <Input
                    type="textarea"
                    value={servee.notes}
                    onChange={(val) => setServeeField(i, 'notes', val)}
                  />

                  <ServePricing
                    serveeIndex={i}
                    servee={servee}
                    setServeeField={setServeeField}
                  />
                </>
              )}
            </SectionBody>
          </Section>
        ))}
        <AddServeeContainer>
          <Button variant="primary" onClick={(e) => addServee(e)}>
            Add additional servee
          </Button>
        </AddServeeContainer>

        {Boolean(job.servees[0]?.serve_pricing_id) && (
          <Mailing
            addMailing={addMailing}
            setAddMailing={setAddMailing}
            job={job}
            setServeeField={setServeeField}
          />
        )}
      </Form>
    </Root>
  );
};

export default withTheme(JobCreationScreenComponent);
